import { Injectable, Injector, Provider } from "@angular/core";
import { IWidgetOptions } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { CurrencyConversionType } from "company/components/currency/currency.models";
import { AmortizationModelModalComponent } from "company/components/financial/amortization-model-modal/amortization-model-modal.component";
import { FinancialPeriodModalComponent } from "company/components/financial/financial-period-modal/financial-period-modal.component";
import { AddExistingUserWizardComponent } from "company/components/users/add-existing-user-wizard/add-existing-user-wizard.component";
import { CreateNewUserWizardComponent } from "company/components/users/create-new-user-wizard/create-new-user-wizard.component";
import { InviteUserModalComponent } from "company/components/users/invite-user-modal/invite-user-modal.component";
import { LookupEditModalComponent } from "company/components/utilities/lookup-crud-util/lookup-edit-modal/lookup-edit-modal.component";
import { LookupKeys } from "company/components/utilities/lookup-crud-util/lookup.models";
import { SelectSourceFieldsModalComponent } from "config/components/source/source-fields/source-fields-modal/source-fields-modal.component";
import { cloneDeep } from "lodash";
import { BulkEditRightsComponent } from "reporting/components/rights-explorer/avails-bulk-create-rights/bulk-edit-rights/bulk-edit-rights.component";
import { IDealAssociation } from "reporting/components/rights-explorer/avails-bulk-create-rights/existing-or-new-deal/existing-or-new-deal.models";
import { AddWidgetModalComponent } from "rl-common/components/add-widget-modal/add-widget-modal.component";
import { AdvancedSearchModalComponent } from "rl-common/components/advanced-search/advanced-search-modal/advanced-search-modal.component";
import { ApplyRelativeRightsProfileModalComponent } from "rl-common/components/apply-relative-rights-profile-modal/apply-relative-rights-profile-modal.component";
import { BulkConfig } from "rl-common/components/bulk-grid/bulk-config.strategy";
import { BulkCreateOptionsModalComponent } from "rl-common/components/bulk-grid/bulk-create-options-modal/bulk-create-options-modal.component";
import { BulkEditsPackage } from "rl-common/components/bulk-grid/bulk-edit.models";
import { AddressEditModalComponent } from "rl-common/components/contacts/addresses/address-edit-modal/address-edit-modal.component";
import { IContactPartyGroup } from "rl-common/components/contacts/models/i-contact-party-group";
import { IContactsDictionaries } from "rl-common/components/contacts/models/i-contacts-dictionaries";
import { IParty } from "rl-common/components/contacts/models/i-party";
import { OverwriteSyncMode } from "rl-common/components/contacts/models/overwrite-sync-mode";
import { CopyEntityModalComponent } from "rl-common/components/copy-entity-modal/copy-entity-modal.component";
import { EditDateAlertsConfigModalComponent } from "rl-common/components/date-alerts/edit-date-alerts-config-modal/edit-date-alerts-config-modal.component";
import { DateOptionsModalComponent } from "rl-common/components/date-edit/date-options-modal/date-options-modal.component";
import { EditEntityModalComponent } from "rl-common/components/edit-entity/edit-entity-modal/edit-entity-modal.component";
import { EntitySearchModalComponent } from "rl-common/components/entities/entity-search/entity-search-modal/entity-search-modal.component";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { SendForESignatureComponent } from "rl-common/components/execute-workflow-modal/execute-workflow-wizard/send-for-esignature/send-for-esignature.component";
import { LaunchAllocationModalComponent } from "rl-common/components/financial/launch-allocation-modal/launch-allocation-modal.component";
import { EntityCharDataGridDataSource } from "rl-common/components/grid/datasource/entity-chardata-grid.datasource";
import { AmountInvoiceErrorsComponent } from "rl-common/components/list-group-actions/amount-actions/amount-invoice-errors/amount-invoice-errors.component";
import { IAmountInvoiceRelationship } from "rl-common/components/list-group-actions/amount-actions/amount-invoice-errors/amount-invoice-errors.models";
import { OriginalConfirmModalComponent } from "rl-common/components/media/original-confirm-modal/original-confirm-modal.component";
import { AddEditAllocationModalComponent } from "rl-common/components/modals/add-edit-allocation-modal/add-edit-allocation-modal.component";
import { AddEditCurrencyConversionModalComponent } from "rl-common/components/modals/add-edit-currency-conversion-modal/add-edit-currency-conversion-modal.component";
import { AddEditPolicyModalComponent } from "rl-common/components/modals/add-edit-policy-modal/add-edit-policy-modal.component";
import { AddEditRoleModalComponent } from "rl-common/components/modals/add-edit-role-modal/add-edit-role-modal.component";
import { AddEditWfRoleModalComponent } from "rl-common/components/modals/add-edit-wf-role-modal/add-edit-wf-role-modal.component";
import { RoleAction } from "rl-common/components/modals/add-edit-wf-role-modal/add-edit-wf-role-modal.models";
import { ApplyDataDefaultModalComponent } from "rl-common/components/modals/apply-data-default-modal/apply-data-default-modal.component";
import { ConfirmationModalComponent } from "rl-common/components/modals/confirmation-modal/confirmation-modal.component";
import { CreateDataDefaultModalComponent } from "rl-common/components/modals/create-data-default-modal/create-data-default-modal.component";
import { DeleteConfirmState } from "rl-common/components/modals/delete-confirmation-modal/delete-confirmation-modal.component.models";
import { GdprModalComponent } from "rl-common/components/modals/gdpr-modal/gdpr-modal.component";
import { GrantConfigComparisonAccessModalComponent } from "rl-common/components/modals/grant-config-comparison-access-modal/grant-config-comparison-access-modal.component";
import { JobManagerModalComponent } from "rl-common/components/modals/job-manager-modal/job-manager-modal.component";
import { JobProgressModalComponent } from "rl-common/components/modals/job-progress-modal/job-progress-modal.component";
import { IJobOptions } from "rl-common/components/modals/job-progress-modal/job-progress/job-progress.component.models";
import { LockedParentsModalComponent } from "rl-common/components/modals/locked-parents-modal/locked-parents-modal.component";
import { RemoveAssociationsModalComponent } from "rl-common/components/modals/remove-associations-modal/remove-associations-modal.component";
import { SyncMetadataToCatalogHierarchyModalComponent } from "rl-common/components/modals/sync-metadata-to-catalog-hierarchy-modal/sync-metadata-to-catalog-hierarchy-modal.component";
import { ValidateConfigComparisonAccessTokenModalComponent } from "rl-common/components/modals/validate-config-comparison-access-token-modal/validate-config-comparison-access-token-modal.component";
import { ValidationErrorsModalComponent } from "rl-common/components/modals/validation-errors-modal/validation-errors-modal.component";
import { NewAssociationModalComponent } from "rl-common/components/new-association-modal/new-association-modal.component";
import { OverwriteCatalogHierarchyModalComponent } from "rl-common/components/overwrite-catalog-hierarchy-modal/overwrite-catalog-hierarchy-modal.component";
import { PreviewDocumentModalComponent } from "rl-common/components/preview-document-modal/preview-document-modal.component";
import { CreateRoyaltyWizardComponent } from "rl-common/components/royalties/create-royalty-wizard/create-royalty-wizard.component";
import { SelectAvatarModalComponent } from "rl-common/components/select-avatar-modal/select-avatar-modal.component";
import { CharTypeId } from "rl-common/consts";
import { SearchType } from "rl-common/factories";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharDataExtDataDateMath } from "rl-common/models/i-char-data-ext-data-date-math";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntityId } from "rl-common/models/i-entity-id";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IAmortizationModel } from "rl-common/services/amortization/amortization.models";
import { IAssociatedRecords } from "rl-common/services/associations/association.models";
import { IFinancialPeriod } from "rl-common/services/financial-period/financial-period.models";
import { IPreviewDocument } from "rl-common/services/workflow/workflow.models";
import { of } from "rxjs";
import { take } from "rxjs/operators";
import { CalculateAllocationModalComponent } from "../components/financial/calculate-allocation-modal/calculate-allocation-modal.component";
import { BulkCreateAmountsModalComponent } from "./../components/bulk-grid/bulk-create-amounts/bulk-create-amounts-modal/bulk-create-amounts-modal.component";
import { BulkGridModalComponent } from "./../components/bulk-grid/bulk-grid-modal/bulk-grid-modal.component";
import { BulkMultipleTemplateConfirmModalComponent } from "./../components/bulk-grid/bulk-multiple-template-confirm-modal/bulk-multiple-template-confirm-modal.component";
import { AssociateInvoiceModalComponent } from "./../components/list-group-actions/amount-actions/associate-invoice-modal/associate-invoice-modal.component";
import { WorkflowWarningModalComponent } from "./../components/list-group-actions/workflow-actions/workflow-warning-modal/workflow-warning-modal.component";
import { PreviewMediaModalComponent } from "./../components/media/preview-media-modal/preview-media-modal.component";
import { ChangePasswordModalComponent } from "./../components/modals/change-password-modal/change-password-modal.component";
import { DeleteConfirmationModalComponent } from "./../components/modals/delete-confirmation-modal/delete-confirmation-modal.component";
import { DocumentAssociatedUserModalComponent } from "./../components/modals/document-associated-user-modal/document-associated-user-modal.component";
import { EditContactModalComponent } from "./../components/modals/edit-contact-modal/edit-contact-modal.component";
import { GoogleAuthenticatorModalComponent } from "./../components/modals/google-authenticator-modal/google-authenticator-modal.component";
import { MoveOrCopyEntitiesModalComponent } from "./../components/modals/move-or-copy-entities-modal/move-or-copy-entities-modal.component";
import { RevokeOrGrantAccessModalComponent } from "./../components/modals/revoke-or-grant-access-modal/revoke-or-grant-access-modal.component";
import { WfActionsStatusesModalComponent } from "./../components/modals/wf-actions-statuses-modal/wf-actions-statuses-modal.component";
import { ISavedSearch } from "./advanced-search/advanced-search.models";
import { IAllocationModel } from "./allocation/allocation.models";
import { IAssetAttachmentModel } from "./attachment/attachment.models";
import { ICurrencyConversion, ICurrencySettings, ISecurityPolicy, IUserRole, IWfRole } from "./company/company.models";
import { IDataDefaultSet } from "./data-default-set/data-default-set.models";
import { ISourceFieldSearchOptions } from "./entity-config/entity-config.models";
import { IEntitySelected } from "./entity/models/i-entity-selected";
import { ITemplateCharData } from "./entity/models/i-template-char-data";
import { IGridView } from "./grid-view/models/i-grid-view";
import { IValidationErrors } from "./mod-detail/models/i-validation-errors";
import { ModalBuilder } from "./modal-builder/modal-builder";
import { ModalServiceAbstract } from "./modal.service.abstract";
import { ISearchRequestModel } from "./search/models/search-request.model";
import { IAvailableWorkflowActions } from "./workflow/workflow.messages";


@Injectable({ providedIn: "root" })
/**
 * Don't inject this, use ModalServiceAbstract instead
 */
class ModalService extends ModalServiceAbstract {

	constructor(private readonly _modalBuilder: ModalBuilder) {
		super();
	}

	public confirm(title: string, message: string, btnOkText: string = "OK", btnCancelText: string = "Cancel", dialogSize: "sm" | "lg" = "sm") {
		return this._modalBuilder.build<ConfirmationModalComponent>(ConfirmationModalComponent)
			.size(dialogSize)
			.open(component => {
				component.title = title;
				component.message = message;
				component.btnOkText = btnOkText;
				component.btnCancelText = btnCancelText;
				return component.onConfirm;
			});
	}

	public deleteConfirm(recordLabel: string, state: DeleteConfirmState[], entitySelectFn: (row) => IEntitySelected, dialogSize: "sm" | "lg" = "sm", showChips = true) {
		return this._modalBuilder.build<DeleteConfirmationModalComponent>(DeleteConfirmationModalComponent)
			.backdrop("static")
			.size(dialogSize)
			.open(component => {
				component.recordLabel = recordLabel;
				component.states = state;
				component.entitySelectFn = entitySelectFn;
				component.showChips = showChips;
				return component.onConfirm;
			});
	}

	public alert(title: string, message: string, btnOkText: string = "OK", dialogSize: "sm" | "lg" = "sm") {
		return this.confirm(title, message, btnOkText, "", dialogSize);
	}

	public validationErrors(allValidationErrors: IValidationErrors, hasRightConflictErrors: boolean, financialErrors: string[], calcInProgress: boolean, royaltyErrors: string[], injector: Injector) {
		return this._modalBuilder.build<ValidationErrorsModalComponent>(ValidationErrorsModalComponent)
			.injector(injector)
			.closeOnOutput(false)
			.open(component => {
				component.title = "Validation Results";
				component.allValidationErrors = allValidationErrors;
				component.financialErrors = financialErrors;
				component.hasRightConflictErrors = hasRightConflictErrors;
				component.calcInProgress = calcInProgress;
				component.royaltyErrors = royaltyErrors;
				return component.onRefresh;
			});
	}

	public newAssociation(charTypeId: CharTypeId,
		parentAssociations: IAssociatedRecords,
		parentTitle: string,
		parentTemplateId: number,
		showExisting: boolean,
		relationshipType: RelationshipTypes = RelationshipTypes.Child,
		relationshipDirectionTypes: RelationshipTypes[] = [],
		injector: Injector,
		isLocked = false,
		hasValidCreateTemplates = true,
		keywords: string = "") {
		return this._modalBuilder
			.build<NewAssociationModalComponent>(NewAssociationModalComponent)
			.injector(injector)
			.open(component => {
				component.charTypeId = charTypeId;
				component.parentAssociations = parentAssociations;
				component.parentTitle = parentTitle;
				component.parentTemplateId = parentTemplateId;
				component.showExisting = showExisting;
				component.searchType = SearchType.NewRelationship;
				component.relationshipType = relationshipType;
				component.relationshipDirectionTypes = relationshipDirectionTypes;
				component.hasValidCreateTemplates = hasValidCreateTemplates;
				component.keywords = keywords;
				component.isLocked = isLocked;
				return component.onCreate;
			});
	}

	public newContactAssociation(parentCharTypeId: number, parentRecId: number, parentTemplateId: number, parentTitle: string,
		isMultiple: boolean = true, partyId: number, defaultSelectedIds: number[] = [], keywords: string = "") {
		const parentAssociations: IAssociatedRecords = {
			charTypeId: parentCharTypeId,
			recordIds: [parentRecId],
			model: null,
			count: 1
		};
		return this._modalBuilder.build<NewAssociationModalComponent>(NewAssociationModalComponent)
			.size("lg")
			.open(component => {
				component.charTypeId = CharTypeId.User;
				component.parentAssociations = parentAssociations;
				component.parentTitle = parentTitle;
				component.parentTemplateId = parentTemplateId;
				component.shouldSave = false;
				component.defaultSelectedIds = defaultSelectedIds;
				component.isMultiple = isMultiple;
				component.searchType = defaultSelectedIds.length > 0 ? SearchType.EditRelationship : SearchType.NewRelationship;
				component.partyId = partyId;
				component.relationshipType = RelationshipTypes.Child;
				component.keywords = keywords;
				return component.onApply;
			});
	}


	public editContact(recordId: number, dictionaries: IContactsDictionaries, dealId: number, contactNameChar: ICharacteristicData) {
		return this._modalBuilder
			.build<EditContactModalComponent>(EditContactModalComponent)
			.open(component => {
				component.recordId = recordId;
				component.dictionaries = dictionaries;
				component.dealId = dealId;
				component.contactNameChar = contactNameChar;
				return component.onComplete;
			});
	}

	public openGoogleAuthenticator(username: string, otpUrl: string, secretKey: string, isGoogleAuthenticatorEnabled: boolean) {
		return this._modalBuilder
			.build<GoogleAuthenticatorModalComponent>(GoogleAuthenticatorModalComponent)
			.open(component => {
				component.username = username;
				component.otpUrl = otpUrl;
				component.secretKey = secretKey;
				component.isGoogleAuthenticatorEnabled = isGoogleAuthenticatorEnabled;
				return component.googleAuthenticatorSetupComplete;
			});
	}

	public openChangePassword() {
		this._modalBuilder.plain<ChangePasswordModalComponent>(ChangePasswordModalComponent).open();
	}

	public openAdvancedSearchBuilder(savedSearch: ISavedSearch, charTypeId: CharTypeId, parentCharTypeId: CharTypeId = null, parentTemplateId: number = null) {
		return this._modalBuilder
			.build<AdvancedSearchModalComponent>(AdvancedSearchModalComponent)
			.withCancelFn(component => component.onCancel)
			.open(component => {
				component.parentCharTypeId = parentCharTypeId;
				component.parentTemplateId = parentTemplateId;
				component.savedSearch = cloneDeep(savedSearch);
				component.charTypeId = charTypeId;
				return component.onApplySearch;
			});
	}

	public openApplyRelativeRightsModal(profile: IEntitySearchDoc, recordId: number, charTypeId: number, templateId: number, catalogsSelected: IRecordTitle[] = []) {
		return this._modalBuilder
			.build<ApplyRelativeRightsProfileModalComponent>(ApplyRelativeRightsProfileModalComponent)
			.open(component => {
				component.profile = profile;
				component.templateId = templateId;
				component.charTypeId = charTypeId;
				component.recordId = recordId;
				component.catalogsSelected = catalogsSelected;
				return component.onCreate;
			});
	}

	public openSelectAvatarModal() {
		return this._modalBuilder
			.build<SelectAvatarModalComponent>(SelectAvatarModalComponent)
			.open(component => component.onSave);
	}

	public confirmOriginalMedia() {
		return this._modalBuilder
			.build<OriginalConfirmModalComponent>(OriginalConfirmModalComponent)
			.open(component => component.onConfirm);
	}

	public previewMedia(attachment: IAssetAttachmentModel) {
		return this._modalBuilder.build<PreviewMediaModalComponent>(PreviewMediaModalComponent)
			.open(component => {
				component.attachment = attachment;
				return component.onClose;
			});
	}

	public jobProgress(jobId: string, isManualClose = false, isCancelable = false, allowCloseBeforeComplete = false, cancelButtonText = "Cancel", routerLink = [], routerLinkText = "", warningMessages = [], titleOverride = "") {
		const jobOptions: IJobOptions = {
			isManualClose,
			isCancelable,
			cancelButtonText,
			routerLink,
			routerLinkText
		};
		return this._modalBuilder.build<JobProgressModalComponent>(JobProgressModalComponent)
			.size("lg")
			.open(component => {
				component.jobId = jobId;
				component.jobOptions = jobOptions;
				component.allowCloseBeforeComplete = allowCloseBeforeComplete;
				component.warningMessage = warningMessages;
				component.titleOverride = titleOverride;
				return component.onComplete;
			});
	}

	public jobManager() {
		return this._modalBuilder.build<JobManagerModalComponent>(JobManagerModalComponent)
			.size("lg")
			.open(component => component.onComplete);
	}

	public sendForEsignature() {
		this._modalBuilder.plain<SendForESignatureComponent>(SendForESignatureComponent)
			.open();
	}

	public associateInvoice(parentEntityId: IEntityId, amountModels: ISearchRequestModel[]) {
		return this._modalBuilder
			.build<AssociateInvoiceModalComponent>(AssociateInvoiceModalComponent)
			.open(component => {
				component.parentEntityId = parentEntityId;
				component.amountModels = amountModels;
				return component.onAssociateComplete;
			});
	}

	public amountInvoiceError(amountInvoiceRelationship: IAmountInvoiceRelationship[]) {
		return this._modalBuilder
			.build<AmountInvoiceErrorsComponent>(AmountInvoiceErrorsComponent)
			.open(component => {
				component.amountInvoiceRelationships = amountInvoiceRelationship;
				return component.onComplete;
			});
	}

	copyEntity(entityId: string, parentEntityId: string = null, parentTemplateId: number = null) {
		return this._modalBuilder
			.build<CopyEntityModalComponent>(CopyEntityModalComponent)
			.withCancelFn(c => c.onCancel)
			.open(component => {
				component.entityId = entityId;
				component.parentId = parentEntityId;
				component.parentTemplateId = parentTemplateId;
				return component.onComplete;
			});
	}

	copyOrMoveEntities(entityId: string, isCopy: boolean, childModel: ISearchRequestModel, charTypeId: number,
		childCharTypeId: number, childCount: number, templateId: number, associatedTemplates: number[]) {
		return this._modalBuilder
			.build<MoveOrCopyEntitiesModalComponent>(MoveOrCopyEntitiesModalComponent)
			.open(component => {
				component.entityId = entityId;
				component.charTypeId = charTypeId;
				component.isCopy = isCopy;
				component.childModel = childModel;
				component.childCount = childCount;
				component.childCharTypeId = childCharTypeId;
				component.templateId = templateId;
				component.associatedTemplates = associatedTemplates;
				return component.onComplete;
			});
	}

	createRoyalty(template: ICharacteristicTemplate, injector: Injector) {
		return this._modalBuilder
			.build<CreateRoyaltyWizardComponent>(CreateRoyaltyWizardComponent)
			.injector(injector)
			.withCancelFn(c => c.onCancel)
			.open(component => {
				component.template = template;
				return component.onComplete;
			});
	}

	calculateAllocation(dealId: string) {
		return this._modalBuilder
			.build<CalculateAllocationModalComponent>(CalculateAllocationModalComponent)
			.open(component => {
				component.dealId = dealId;

				return component.onCalculate;
			});
	}

	launchAllocation(dealId: string, selected: string[], totalFees: number, totalFeesSym: string) {
		return this._modalBuilder
			.build<LaunchAllocationModalComponent>(LaunchAllocationModalComponent)
			.open(component => {
				component.dealId = dealId;
				component.selected = selected;
				component.totalFees = totalFees;
				component.totalFeesSym = totalFeesSym;
				return component.onCalculate;
			});
	}

	previewDocument(file: IPreviewDocument) {
		return this._modalBuilder
			.build<PreviewDocumentModalComponent>(PreviewDocumentModalComponent)
			.open(component => {
				component.file = file;
				return component.onClose;
			});
	}

	revokeOrGrantAccess(isGrantAccess: boolean, parentTitle: string, parentTemplateId: number, associatedRecords: IAssociatedRecords, selectedEntityIds: string[]) {
		return this._modalBuilder
			.build<RevokeOrGrantAccessModalComponent>(RevokeOrGrantAccessModalComponent)
			.open(component => {
				component.isGrantAccess = isGrantAccess;
				component.parentTitle = parentTitle;
				component.parentTemplateId = parentTemplateId;
				component.associatedRecords = associatedRecords;
				component.selectedEntityIds = selectedEntityIds;
				return component.onApply;
			});
	}

	searchEntity(searchOptions: SearchOptions) {
		return this._modalBuilder
			.build<EntitySearchModalComponent>(EntitySearchModalComponent)
			.open(component => {
				component.searchOptions = searchOptions;
				return component.onApply;
			});
	}

	syncMetadataToCatalogHierarchy(charTypeId: number, recordId: number, charMetaData: ICharacteristicMetaData[]) {
		return this._modalBuilder
			.build<SyncMetadataToCatalogHierarchyModalComponent>(SyncMetadataToCatalogHierarchyModalComponent)
			.open(component => {
				component.charTypeId = charTypeId;
				component.recordId = recordId;
				component.charMetaData = charMetaData;
				return component.onApply;
			});
	}

	overwriteCatalogHierarchy(parentEntityId: IEntityId, contactPartyGroups: IContactPartyGroup[], parties: { [partyId: number]: IParty }, overwriteSyncMode: OverwriteSyncMode, injector: Injector) {
		return this._modalBuilder
			.build<OverwriteCatalogHierarchyModalComponent>(OverwriteCatalogHierarchyModalComponent)
			.injector(injector)
			.open(component => {
				component.parentEntityId = parentEntityId;
				component.contactPartyGroups = contactPartyGroups;
				component.partyDictionary = parties;
				component.overwriteSyncMode = overwriteSyncMode;
				return component.onComplete;
			});
	}

	showGdpr() {
		this._modalBuilder.plain<GdprModalComponent>(GdprModalComponent).open();
	}

	showWorkflowWarning(availableWorkflowActions: IAvailableWorkflowActions) {
		return this._modalBuilder.build<WorkflowWarningModalComponent>(WorkflowWarningModalComponent)
			.open(comp => {
				comp.availableWorkflowActions = availableWorkflowActions;
				return of();
			});
	}

	createDataDefault(model: ISearchRequestModel, charTypeId: number, setCharTypeId: CharTypeId) {
		return this._modalBuilder
			.build<CreateDataDefaultModalComponent>(CreateDataDefaultModalComponent)
			.open(component => {
				component.model = model;
				component.charTypeId = charTypeId;
				component.setCharTypeId = setCharTypeId;
				return component.onApply;
			});
	}

	openDateOptionsModal(cmd: ICharacteristicMetaData, recCharId: number, dateMath: ICharDataExtDataDateMath, alerts: ICharDataExtDataAlert[], isRelative: boolean, parentEntityId: string = "", isBulkEdit: boolean, injector: Injector) {
		return this._modalBuilder.build<DateOptionsModalComponent>(DateOptionsModalComponent)
			.injector(injector)
			.open(component => {
				component.cmd = cmd;
				component.recCharId = recCharId;
				component.dateMath = dateMath;
				component.alerts = alerts;
				component.isRelative = isRelative;
				component.parentEntityId = parentEntityId;
				component.isBulkEdit = isBulkEdit;
				return component.onChange;
			});
	}

	openEditDateAlertsConfigModal(cmd: ICharacteristicMetaData, cmds: ICharacteristicMetaData[], recCharId: number, alerts: ICharDataExtDataAlert[], editingAlert: ICharDataExtDataAlert, parentEntityId: string = "", isBulkEdit: boolean, isEditAlert: boolean, dateAlertEditingLabel: string = "", charTypeId: number, templateId: number, characteristicId: number, notificationMessagesEnabled: boolean, injector: Injector) {
		return this._modalBuilder.build<EditDateAlertsConfigModalComponent>(EditDateAlertsConfigModalComponent)
			.injector(injector)
			.open(component => {
				component.cmd = cmd;
				component.cmds = cmds;
				component.recCharId = recCharId;
				component.alerts = alerts;
				component.editingAlert = editingAlert;
				component.parentEntityId = parentEntityId;
				component.isBulkEdit = isBulkEdit;
				component.isEditAlert = isEditAlert;
				component.dateAlertEditingLabel = dateAlertEditingLabel;
				component.charTypeId = charTypeId;
				component.templateId = templateId;
				component.characteristicId = characteristicId;
				component.notificationMessagesEnabled = notificationMessagesEnabled;
				return component.onChange;
			});
	}

	bulkModal(config: BulkConfig, templateId: number, injector: Injector, gridView: IGridView = null) {
		return this._modalBuilder.build<BulkGridModalComponent>(BulkGridModalComponent)
			.injector(injector)
			.backdrop("static")
			.open(comp => {
				comp.config = config;
				comp.gridView = gridView;
				comp.template = templateId;
				return comp.onComplete;
			});
	}

	bulkCreateAmounts(injector: Injector, templateId: number, recordIds: number[], charTypeId: CharTypeId) {
		return this._modalBuilder.build<BulkCreateAmountsModalComponent>(BulkCreateAmountsModalComponent)
			.injector(injector)
			.closeOnOutput(true)
			.open(comp => {
				comp.charTypeId = charTypeId;
				comp.templateId = templateId;
				comp.recordIds = recordIds;
				return comp.onComplete;
			});
	}

	bulkCreateOptionsModal(injector: Injector, charTypeId: CharTypeId, template: ICharacteristicTemplate) {
		return this._modalBuilder.build<BulkCreateOptionsModalComponent>(BulkCreateOptionsModalComponent)
			.injector(injector)
			.closeOnOutput(true)
			.open(component => {
				component.charTypeId = charTypeId;
				component.template = template;
				return component.onComplete;
			});
	}

	confirmBulkMultipleTemplates(bulkPackage: BulkEditsPackage<ICharacteristicData[]>, charTypeId: CharTypeId, templateId: number, dataSource: EntityCharDataGridDataSource) {
		return this._modalBuilder.build<BulkMultipleTemplateConfirmModalComponent>(BulkMultipleTemplateConfirmModalComponent)
			.closeOnOutput(true)
			.open(comp => {
				comp.bulkPackage = bulkPackage;
				comp.charTypeId = charTypeId;
				comp.templateId = templateId;
				comp.dataSource = dataSource;
				return comp.onConfirm;
			});
	}

	bulkEditRightsModal(injector: Injector, deal: IDealAssociation, templateId: number, config: BulkConfig, charData: ITemplateCharData[]) {
		return this._modalBuilder.build<BulkEditRightsComponent>(BulkEditRightsComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.templateId = templateId;
				component.deal = deal;
				component.config = config;
				component.initialData = charData;
				return component.onComplete;
			});
	}

	showDocumentAssociatedUsers(charTypeId: number, recordId: number, parentCharTypeId: number, parentRecordId: number) {
		return this._modalBuilder.build<DocumentAssociatedUserModalComponent>(DocumentAssociatedUserModalComponent)
			.closeOnOutput(true)
			.open(comp => {
				comp.charTypeId = charTypeId;
				comp.recordId = recordId;
				comp.parentCharTypeId = parentCharTypeId;
				comp.parentRecordId = parentRecordId;
				return comp.onComplete;
			});
	}

	removeAssociations(parentCharTypeId: CharTypeId, parentRecordId: number, childCharTypeId: CharTypeId, direction: RelationshipTypes, childRecordIds: IRecordTitle[],
		isDataBridged: boolean = false, parentTitle: string = "", removingParentAssociationFromChildModDetail: boolean = false) {
		return this._modalBuilder.build<RemoveAssociationsModalComponent>(RemoveAssociationsModalComponent)
			.open(component => {
				component.parentCharTypeId = parentCharTypeId;
				component.parentRecordId = parentRecordId;
				component.childCharTypeId = childCharTypeId;
				component.direction = direction;
				component.childRecords = childRecordIds;
				component.parentTitle = parentTitle;
				component.removingParentAssociationFromChildModDetail = removingParentAssociationFromChildModDetail;
				component.isDataBridged = isDataBridged;
				return component.onRemove;
			});
	}

	addWidget(currentWidgets: IWidgetOptions[]) {
		return this._modalBuilder.build<AddWidgetModalComponent>(AddWidgetModalComponent)
			.open(component => {
				component.currentWidgets = currentWidgets;
				return component.onWidgetsSelected;
			});
	}

	wfActionsStatusesModal(wfRole: IWfRole) {
		return this._modalBuilder.build<WfActionsStatusesModalComponent>(WfActionsStatusesModalComponent)
			.open(component => {
				component.role = wfRole;
				return component.onComplete;
			});
	}

	addEditRoleModal(policies: ISecurityPolicy[], userRole: IUserRole = null, existingRoles: string[], isCopy: boolean = false) {
		return this._modalBuilder.build<AddEditRoleModalComponent>(AddEditRoleModalComponent)
			.open(component => {
				component.policies = policies;
				component.role = userRole;
				component.existingRoles = existingRoles;
				component.isCopy = isCopy;
				return component.onComplete;
			});
	}

	createWfRoleModal(existingNames: string[] = []) {
		return this._modalBuilder.build<AddEditWfRoleModalComponent>(AddEditWfRoleModalComponent)
			.open(component => {
				component.action = RoleAction.Create;
				component.existingNames = existingNames;
				return component.onComplete;
			});
	}

	editWfRoleModal(wfRole: IWfRole, existingNames: string[] = []) {
		return this._modalBuilder.build<AddEditWfRoleModalComponent>(AddEditWfRoleModalComponent)
			.open(component => {
				component.role = wfRole;
				component.action = RoleAction.Edit;
				component.existingNames = existingNames;
				return component.onComplete;
			});
	}

	copyWfRoleModal(wfRole: IWfRole = null, existingNames: string[] = []) {
		return this._modalBuilder.build<AddEditWfRoleModalComponent>(AddEditWfRoleModalComponent)
			.open(component => {
				component.role = wfRole;
				component.action = RoleAction.Copy;
				component.existingNames = existingNames;
				return component.onComplete;
			});
	}

	addEditPolicyModal(policy: ISecurityPolicy = null, policyType: number, isCopy: boolean = false) {
		return this._modalBuilder.build<AddEditPolicyModalComponent>(AddEditPolicyModalComponent)
			.open(component => {
				component.policy = policy;
				component.policyType = policyType;
				component.isCopy = isCopy;
				return component.onComplete.pipe(
					take(1)
				);
			});
	}

	addEditCurrencyConversion(conversionType: CurrencyConversionType, currencySettings: ICurrencySettings, fromLabel: string = null, toLabel: string = null, currencyConversion: ICurrencyConversion = null) {
		return this._modalBuilder.build<AddEditCurrencyConversionModalComponent>(AddEditCurrencyConversionModalComponent)
			.open(component => {
				component.conversionType = conversionType;
				component.fromLabel = fromLabel;
				component.toLabel = toLabel;
				component.currencyConversion = currencyConversion;
				component.currencySettings = currencySettings;
				return component.onComplete;
			});
	}

	giveLoginPermission(injector: Injector) {
		return this._modalBuilder.build<InviteUserModalComponent>(InviteUserModalComponent)
			.injector(injector)
			.open(component => component.onComplete);
	}

	editPeriod(period: IFinancialPeriod, isView: boolean = false) {
		return this._modalBuilder.build<FinancialPeriodModalComponent>(FinancialPeriodModalComponent)
			.open(component => {
				component.financialPeriod = period;
				component.isView = isView;
				return component.onComplete;
			});
	}

	editAmortizationModel(model: IAmortizationModel, isView: boolean = false) {
		return this._modalBuilder.build<AmortizationModelModalComponent>(AmortizationModelModalComponent)
			.open(component => {
				component.amortizationModel = model;
				component.isView = isView;
				return component.onComplete;
			});
	}

	createNewUserWizard() {
		return this._modalBuilder.build<CreateNewUserWizardComponent>(CreateNewUserWizardComponent)
			.closeOnOutput(true)
			.open(component => component.onComplete);
	}

	addExistingUserWizard(email: string = null) {
		return this._modalBuilder.build<AddExistingUserWizardComponent>(AddExistingUserWizardComponent)
			.closeOnOutput(true)
			.open(component => {
				component.email = email;
				return component.onComplete;
			});
	}

	editLookup(lookupKey: LookupKeys) {
		return this._modalBuilder.build<LookupEditModalComponent>(LookupEditModalComponent)
			.closeOnOutput(true)
			.open(component => {
				component.lookupKey = lookupKey;
				return component.onComplete;
			});
	}

	applyDataDefault(charTypeId: number, recordId: number, dataDefaults: IDataDefaultSet[]) {
		return this._modalBuilder.build<ApplyDataDefaultModalComponent>(ApplyDataDefaultModalComponent)
			.closeOnOutput(true)
			.open(component => {
				component.charTypeId = charTypeId;
				component.recordId = recordId;
				component.dataDefaults = dataDefaults;
				return component.onComplete;
			});
	}

	public editEntity(injector: Injector, editingEntityId: string, parentEntityId: string = null, baseEntityId: string = null) {
		return this._modalBuilder.build<EditEntityModalComponent>(EditEntityModalComponent)
			.size("lg")
			.injector(injector)
			.closeOnOutput(true)
			.open(component => {
				component.parentEntityId = parentEntityId;
				component.editingEntityId = editingEntityId;
				component.baseEntityId = baseEntityId;
				return component.onComplete;
			});
	}

	addEditAllocationModel(isCreate: boolean, templateId: number, allocationModel: IAllocationModel = null) {
		return this._modalBuilder.build<AddEditAllocationModalComponent>(AddEditAllocationModalComponent)
			.open(component => {
				component.isCreate = isCreate;
				component.templateId = templateId;
				component.allocationModel = allocationModel;
				return component.onComplete;
			});
	}

	selectSourceFields(charTypeId: number, options: ISourceFieldSearchOptions = null) {
		return this._modalBuilder.build<SelectSourceFieldsModalComponent>(SelectSourceFieldsModalComponent).open(component => {
			component.charTypeId = charTypeId;
			component.options = options;
			return component.onComplete;
		});
	}

	grantConfigComparisonAccess(environment: string, expirationHours: number) {
		return this._modalBuilder.build<GrantConfigComparisonAccessModalComponent>(GrantConfigComparisonAccessModalComponent)
			.keyboard(false)
			.backdrop("static")
			.open(component => {
				component.environmentName = environment;
				component.expirationHours = expirationHours;
				return component.onComplete;
			});
	}

	validateConfigComparisonAccessToken(environment: string) {
		return this._modalBuilder.build<ValidateConfigComparisonAccessTokenModalComponent>(ValidateConfigComparisonAccessTokenModalComponent)
			.keyboard(false)
			.backdrop("static")
			.open(component => {
				component.environmentName = environment;
				return component.onComplete;
			});
	}

	public lockedParentsModal(recordsWithLockedParents: Map<IEntitySearchDoc, IEntitySearchDoc[]>) {
		return this._modalBuilder.build<LockedParentsModalComponent>(LockedParentsModalComponent)
			.open(component => {
				component.recordsWithLockedParents = recordsWithLockedParents;
				return component.onConfirm;
			});
	}

	upsertAddress(parentEntityId: string, template: ICharacteristicMetaDataCollection, addressQuery: ISearchRequestModel,
		recordId: number = null, charData: ICharacteristicData[] = []) {
		return this._modalBuilder.build<AddressEditModalComponent>(AddressEditModalComponent)
			.open(component => {
				component.charData = charData;
				component.recordId = recordId;
				component.template = template;
				component.parentEntityId = parentEntityId;
				component.addressQuery = addressQuery;
				return component.onComplete;
			});
	}
}


export const MODAL_SERVICE_PROVIDER: Provider = {
	provide: ModalServiceAbstract,
	useClass: ModalService
}