import { Provider } from "@angular/core";
import { AVAILSBULKCREATERIGHTS_MODAL_PROVIDER } from "reporting/components/rights-explorer/avails-bulk-create-rights/avails-bulk-create-rights.component";

import { FINDALLIANTENTITIES_MODAL_PROVIDER } from "./components/alliant-details/find-alliant-entities-modal/find-alliant-entities-modal.component";
import { FINDRLENTITIES_MODAL_PROVIDER } from "./components/alliant-details/find-rl-entities-modal/find-rl-entities-modal.component";
import { CLIPBOARD_MODAL_PROVIDER } from "./components/clipboard/clipboard.component";
import { EDITCLIPBOARD_MODAL_PROVIDER } from "./components/clipboard/edit-clipboard/edit-clipboard.component";
import { CREATENEWCHARDATA_MODAL_PROVIDER } from "./components/create-new-char-data-modal/create-new-char-data-modal.component";
import { EDITENTITY_MODAL_PROVIDER } from "./components/edit-entity/edit-entity-modal/edit-entity-modal.component";
import { EXECUTEWORKFLOW_MODAL_PROVIDER } from "./components/execute-workflow-modal/execute-workflow-modal.component";
import { ASSOCENTITYSELECT_MODAL_PROVIDER } from "./components/grid/modals/assoc-entity-select-modal/assoc-entity-select-modal.component";
import { MODAL_SERVICE_PROVIDER } from "./services/modal.service";
import { EDITWORKSHEET_MODAL_PROVIDER } from "./components/worksheets/edit-worksheet/edit-worksheet.component";

export const RL_COMMON_PROVIDERS: Provider[] = [
	MODAL_SERVICE_PROVIDER,
	EDITCLIPBOARD_MODAL_PROVIDER,
	EDITWORKSHEET_MODAL_PROVIDER,
	AVAILSBULKCREATERIGHTS_MODAL_PROVIDER,
	CLIPBOARD_MODAL_PROVIDER,
	EXECUTEWORKFLOW_MODAL_PROVIDER,
	EDITENTITY_MODAL_PROVIDER,
	CREATENEWCHARDATA_MODAL_PROVIDER,
	FINDRLENTITIES_MODAL_PROVIDER,
	FINDALLIANTENTITIES_MODAL_PROVIDER,
	ASSOCENTITYSELECT_MODAL_PROVIDER
];