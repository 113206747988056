import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { find } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntity } from "rl-common/models/i-entity";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ContactService } from "rl-common/services/contact/contact.service";
import { EditContactsService } from "rl-common/services/edit-contacts/edit-contacts.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { switchMap, tap } from "rxjs/operators";
import { EditContactsComponent } from "../contacts/edit-contacts/edit-contacts.component";
import { ISearchEvent } from "../contacts/edit-contacts/edit-party/edit-party.component";
import { IContactPartyGroup } from "../contacts/models/i-contact-party-group";
import { IContactsDictionaries } from "../contacts/models/i-contacts-dictionaries";
import { EntitySearchComponent } from "../entities/entity-search/entity-search.component";
import { SearchOptions } from "../entities/entity-search/entity-search.models";
import { GridSelectState } from "../grid/models/grid-select-state";
import { PanelContentDirective } from "../panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "../panel-switcher/panel-switcher.component";
import { EditMode } from "../panel-switcher/panel-switcher.models";
import { PanelComponent } from "../panel-switcher/panel/panel.component";
import { ParentEntityService } from "./../../services/entity/parent-entity/parent-entity.service";

@Component({
	selector: "rl-associate-contacts",
	templateUrl: "./associate-contacts.component.html",
	styleUrls: ["./associate-contacts.component.scss"],
	providers: [ParentEntityService, EditContactsService],
	imports: [PanelSwitcherComponent, PanelComponent, PanelContentDirective, EditContactsComponent, NgIf, EntitySearchComponent]
})
export class AssociateContactsComponent implements OnInit, OnChanges {
	contactPartyGroups: IContactPartyGroup[];
	dictionaries: IContactsDictionaries = {} as IContactsDictionaries;
	editMode: EditMode = EditMode.Edit;
	selectedPartyId: number;
	selectedContactId: number;
	selectedGroupId: number;
	selectedSequenceNumber: number;
	searchOptions: SearchOptions;
	isLoading = true;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	parentRecId: number;

	@Input()
	parentCharTypeId: number;

	@Input()
	autoCreate: boolean;

	@Input()
	canAutoCreateChildItems: boolean;

	@Input()
	canAssociateCatalogItems: boolean;

	@Input()
	extractedParties: Map<number, IEntity[]>;

	@ViewChild(EditContactsComponent, { static: true })
	editContactsComponent: EditContactsComponent;

	@ViewChild(PanelSwitcherComponent, { static: true })
	panelSwitcher: PanelSwitcherComponent;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onCreate = new EventEmitter<boolean>();

	@Output()
	onBack = new EventEmitter<void>();

	@Output()
	onGoToNextStep = new EventEmitter<void>();

	constructor(
		private router: Router,
		private readonly _contactService: ContactService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _editContactsService: EditContactsService,
		private readonly _oneConfig: OneConfigService
	) { }

	ngOnInit(): void {
		this._parentEntityService.initializeParent({
			charTypeId: this.charTypeId,
			recordId: -1,
			templateId: this.templateId
		});
		this.refreshContacts();
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.extractedParties || (changes.templateId && !changes.templateId.isFirstChange() && changes.templateId.previousValue !== changes.templateId.currentValue)) {
			this.refreshContacts();
		}
	}

	refreshContacts() {
		this._editContactsService.reset();
		const parentRecordId = this.parentRecId ? this.parentRecId : -1;
		this._contactService.getContactsForNewEntity(this.charTypeId, this.templateId, this.parentCharTypeId, parentRecordId)
			.pipe(
				tap(data => {
					this.contactPartyGroups = data.groupedParties;
					this.dictionaries = {
						parties: data.parties,
						partyGroups: data.partyGroups,
						contacts: data.contacts,
						editContacts: data.editContacts,
					};
				}),
				switchMap(() => {
					const partyIds = Object.keys(this.dictionaries.parties).map(p => +p);
					return this._editContactsService.getPartyTemplates(this.charTypeId, this.templateId, partyIds);
				})
			)
			.subscribe(() => {
				if (this.extractedParties) {
					const parties = this._oneConfig.getParties(this.charTypeId, this.templateId);
					Object.keys(this.extractedParties).forEach((key) => {
						const groupId = parties.find(f => f.partyID == Number(key))?.partyGroupID;
						if (groupId > 0) {
							const contacts = this.extractedParties[key];
							contacts.forEach((contact) => {
								const partyFound = this._editContactsService.findParty(groupId, Number(key));
								if (partyFound) {
									partyFound.contacts.push({
										recordId: contact.recordID,
										sequenceNumber: 0
									});
									if (contact.recordID > 0) {
										this.dictionaries.editContacts[contact.recordID] = contact.title;
									}
								}
							});
						}
					});
				}
				this.isLoading = false;
			});
	}

	saveChanges(shouldAutoCreateHierarchy: boolean) {
		this.isLoading = true;
		this.onCreate.emit(shouldAutoCreateHierarchy);
	}

	continueToAutoCreateHierarchy() {
		this.onGoToNextStep.emit();
	}

	saveContacts(entityId: string) {
		return this._editContactsService.save(entityId);
	}

	getContactsForNewEntityRequest() {
		return this._editContactsService.getContactsOnEntity();
	}

	cancel() {
		this._editContactsService.reset();
		this.onCancel.emit();
	}

	public startSearch(event: ISearchEvent) {
		this.editMode = EditMode.Search;
		this.selectedContactId = event.contactRecordId;
		this.selectedPartyId = event.partyId;
		this.selectedGroupId = event.groupId;
		this.selectedSequenceNumber = event.sequenceNumber;
		this.searchOptions = SearchOptionsFactory.buildPanelSearchOptions(this.charTypeId, CharTypeId.User, this.templateId);
		this.searchOptions.filteredTemplateIds = event.filteredTemplateIds;
	}

	public select(event: GridSelectState<number, IEntitySearchDoc>) {
		if (event.selectedValues.size === 0) {
			return;
		}
		this.switchPanel(EditMode.Edit);
		const party = this._editContactsService.findParty(this.selectedGroupId, this.selectedPartyId);
		const selectedValue = Array.from(event.selectedValues)[0];
		if (this.selectedContactId) {
			const contact = find(party.contacts, c => c.recordId === this.selectedContactId);
			contact.recordId = selectedValue.recordID;
		} else {
			party.contacts.push({
				recordId: selectedValue.recordID,
				sequenceNumber: this.selectedSequenceNumber
			});
		}

		if (!this.dictionaries.editContacts[selectedValue.recordID]) {
			this.dictionaries.editContacts[selectedValue.recordID] = selectedValue.title;
		}
	}

	goToNextWizardStep() {
		this.onGoToNextStep.emit();
	}

	goToPreviousWizardStep() {
		this.onBack.emit();
	}

	private switchPanel(editMode: EditMode) {
		this.editMode = editMode;
		if (this.panelSwitcher) {
			this.panelSwitcher.select(this.editMode);
		}
	}
}
