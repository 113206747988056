import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { FinancialPeriodStatus, IFinancialPeriod } from "rl-common/services/financial-period/financial-period.models";
import { FinancialService } from "rl-common/services/financial-period/financial-period.service";
import { Observable, Subscription } from "rxjs";
import { DateInputComponent } from "../../../../common/components/date-input/date-input.component";

@Component({
	selector: "rl-edit-financial-period",
	templateUrl: "./edit-financial-period.component.html",
	imports: [ReactiveFormsModule, NgIf, DateInputComponent]
})
export class EditFinancialPeriodComponent implements OnInit, OnDestroy {

	@Input()
	financialPeriod: IFinancialPeriod;

	@Input()
	isView: boolean;

	@Output()
	onClose = new EventEmitter<boolean>();

	_subscriptions: Subscription[] = [];

	form: UntypedFormGroup;
	isSaving = false;

	constructor(private readonly formBuilder: UntypedFormBuilder, private readonly financialService: FinancialService) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			year: new UntypedFormControl(this.financialPeriod?.year, [Validators.required]),
			period: new UntypedFormControl(this.financialPeriod?.period, [Validators.required]),
			startDate: new UntypedFormControl(this.financialPeriod?.startDate, [Validators.required]),
			endDate: new UntypedFormControl(this.financialPeriod?.endDate, [Validators.required]),
		});
	}

	close(requiresRefresh = false) {
		this.onClose.emit(requiresRefresh);
	}

	save() {
		this.isSaving = true;
		let obs: Observable<void> = null;
		const newPeriod: IFinancialPeriod = {
			id: this.financialPeriod?.id ?? 0,
			year: this.form.controls["year"].value,
			period: this.form.controls["period"].value,
			startDate: this.form.controls["startDate"].value,
			endDate: this.form.controls["endDate"].value,
			status: FinancialPeriodStatus.Open,
			sid: 0
		};
		if (this.financialPeriod == null || this.financialPeriod.id === 0) {
			obs = this.financialService.createFinancialPeriod(newPeriod);
		} else {
			obs = this.financialService.updateFinancialPeriod(newPeriod);
		}

		const sub = obs.subscribe(
			_ => this.close(true),
			_ => this.isSaving = false
		);
		this._subscriptions.push(sub);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(x => x.unsubscribe());
	}
}
