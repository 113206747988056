<div class="modal-header">
	<h2 class="modal-title">Set Allocation Options</h2>
</div>
<div class="modal-body set-allocation-options">
	<div class="d-flex justify-content-between ">
		<h4>Allocating {{totalFeesSym}} {{totalFees | currency:'':''}}</h4>
		<div>
			<span class="border-end">
				<button (click)="toggle()" class="btn btn-link" data-cy="collapseAmounts">
					@if(showAllocationAmounts){
					Collpase Amounts }
					@else{
					Expand Amounts
					}
				</button>
			</span>
			<button (click)="toggleSettings()" class="btn btn-link" data-cy="toggleSettings">
				Settings
				<i class="far fa-angle-right me-2 p-1" [class.fa-angle-right]="showSettings"
					[class.fa-angle-down]="!showSettings"></i>
			</button>
		</div>
	</div>
	<div *ngIf="showSettings" class="row align-items-stretch g-0 mt-3 p-3 mb-4 bg-light rounded-panel">
		<div class="col-3 pe-3 border-end d-flex flex-column justify-content-center">
			<div class="h5"> Dimension Allocation:</div>
			@if (dimensions?.length > 0) {
			<div ngbAccordion #accordion="ngbAccordion">
				@for (dimension of dimensions; track dimension.charValueSourceID; let j =
				$index) {
				<div ngbAccordionItem="{{ dimension.charValueSourceID }}">
					<div ngbAccordionHeader class="bg-white">
						<div class="accordion-button panel__title d-flex justify-content-between align-items-center">
							<button ngbAccordionButton class="cursor-pointer">
								{{dimension.charValueSourceLabel}}
							</button>
							<div class="panel__ops text-nowrap">
								@if (topNodePercentages &&
								topNodePercentages[dimension.charValueSourceID] > 0) {
								<span
									[ngClass]="{'text-success' : topNodePercentages[dimension.charValueSourceID] === 100, 'text-danger' : topNodePercentages[dimension.charValueSourceID] !== 100 }">{{topNodePercentages[dimension.charValueSourceID]}}%
									allocated </span>
								} @else {
								<span>Nothing allocated</span>
								}
							</div>
						</div>
					</div>
					<div ngbAccordionCollapse>
						<div ngbAccordionBody>
							<ng-template>
								@if (lovs[dimension.charValueSourceID]) {
								<rl-tree-list-edit-control #cmp [lovs]='lovs[dimension.charValueSourceID]'
									[dimensionId]="dimension.charValueSourceID"
									(topNodeAmountChanged)='topNodePercentageChange($event, dimension.charValueSourceID)'
									(percentageAmountChanged)='dimensionValuesChanged($event)'
									[rightRelRecId]="dimension.charValueSourceID" [singleMode]="false"
									[isPercentage]="true" [existingDimensionPercentages]="existingDimensionPercentages">
								</rl-tree-list-edit-control>
								<div class="text-end">
									<button (click)="clearAll(cmp)" class="btn btn-sm btn-remove text-muted">
										<i class="far fa-times me-2"></i>Clear all
									</button>
								</div>
								}
							</ng-template>
						</div>
					</div>
				</div>
				}
			</div>
			} @else {
			<div class="p-4 text-center">
				<rl-loader></rl-loader>
			</div>
			}
		</div>
		<div class="col-3 ps-3 pt-3">
			<div class="h5"> Split Allocations:</div>
			<div class="form-check py-1 m-0">
				<input class="form-check-input" type="radio" name="allocationTypeGroup" id="evenlyByCatalogItem"
					[(ngModel)]="allocationType" (change)="onItemChange()" [value]="1" data-cy="evenlyByCatalogItem"
					[disabled]="isLoading">
				<label class="form-check-label" name="allocationTypeGroup" for="evenlyByCatalogItem">
					Evenly by Catalog Item
				</label>
			</div>
			<div class="form-check py-1 m-0">
				<input class="form-check-input" type="radio" name="allocationTypeGroup" id="durationOfRightsGrant"
					[(ngModel)]="allocationType" (change)="onItemChange()" [value]="0" data-cy="durationOfRightsGrant"
					name="allocationTypeGroup" [disabled]="isLoading">
				<label class="form-check-label" for="durationOfRightsGrant">
					By Catalog Rights Duration
				</label>
			</div>
		</div>
		<div class="col-3 pt-3 text-nowrap">
			<div class="h5"> Calculation Method:</div>
			<div class="form-check py-1 m-0">
				<input class="form-check-input" name="viewSelection" type="radio" id="defaultView"
					[(ngModel)]="viewSelection" [value]="0" data-cy="defaultView" (change)="onItemChange()"
					[disabled]="isLoading">
				<label class="form-check-label" for="defaultView">
					Default
				</label>
			</div>
			<div class="form-check py-1 m-0">
				<input class="form-check-input" name="viewSelection" type="radio" id="firstInFirstOut"
					[(ngModel)]="viewSelection" [value]="1" data-cy="firstInFirstOut" (change)="onItemChange()"
					[disabled]="isLoading">
				<label class="form-check-label" for="firstInFirstOut">
					First in, First out
				</label>
			</div>
		</div>
		<div class="col-3 p-3 pb-0 d-flex flex-column">
			<div class="h5">Rights Dimension Model:</div>
			<select class="form-select mt-1" [disabled]="isLoading || !allocationOptions"
				[(ngModel)]="selectedAllocationModel" (change)="allocationModelChanged()"
				data-cy="selectRightsDimensionModel">
				<option [value]="default">Default</option>
				<option *ngFor="let model of allocationOptions" [ngValue]="model">
					{{model.name}}
				</option>
			</select>
			<div class="d-flex justify-content-end mt-auto pt-3">
				<button (click)="clearAllChanges()" class="btn btn-sm btn-light me-3" data-cy="clearAllButton">
					Clear Changes
				</button>
				<button type="button" [disabled]="isDisabled()" class="btn btn-sm btn-info" (click)="calculate()"
					data-dismiss="modal" data-cy="CalculateButton">Calculate</button>
			</div>
		</div>
	</div>
	<rl-grid *ngIf="dataSource" #grid [dataSource]="dataSource" [options]="gridOptions" data-cy="BulkGridRLGrid">
		<div *rlCellTemplate="'allocated_fee_amount'; let cellData" class="allocation-col">
			{{allocationFeeChangeLocale[cellData.rowId] | currency:'':''}}
		</div>
		<ng-container
			*rlGridNestedTemplate="let nestedDataSource;let rowPath = rowPath; let parentRowData = parentRowData; let rowData = rowData">
			<rl-allocation-spread [allocatedAmounts]="catalogSpreads[parentRowData.rowId]"
				[parentAmount]="allocationFeeChangeLocale[parentRowData.rowId]"
				(onAmountChange)="updateAmounts($event, parentRowData.rowId)">
			</rl-allocation-spread>
		</ng-container>
	</rl-grid>

	<div class="d-flex justify-content-start">
		<span class="summed-amounts d-flex p-3">
			<strong class="w-50 px-3 text-end">{{totalFeesSym}} {{allocationFeeTotal | currency:'':''}}</strong>
			<strong class="w-50 px-3">{{allocationFeePercentTotal}} %</strong>
		</span>
		<div class="mt-2 px-3" *ngIf="hasError || hasAnyAmountError">
			<p *ngFor="let error of errors" class="text-danger">{{error}}</p>
			<p *ngIf="hasAnyAmountError" class="text-danger">{{displayAmountErrorMessage}}</p>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()" data-cy="closeButton">Close</button>
	<button type="button" class="btn btn-info"
		[disabled]="allocationFeeTotal !== totalFees || hasAnyAmountError || isSaving" (click)="calculateAllocations()"
		data-cy="calculateButton">Calculate</button>
</div>