import { Component, OnInit } from "@angular/core";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "rl-font-styles",
    templateUrl: "./font-styles.component.html",
    styleUrls: ["./font-styles.component.scss"],
    imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbNavOutlet, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem]
})
export class FontStylesComponent implements OnInit {

	sampleField = null;

	constructor() { }

	ngOnInit(): void {
	}

}
