<h3>Associate Extracted Catalogs to the New Record</h3>
<article>
	<rl-panel-switcher [activeId]="editMode" [isLoading]="isLoading">
		
		<rl-panel id="search">
			<ng-template rlPanelContent>
				<rl-module-association *ngIf="dataSource" [dataSource]="dataSource" [charTypeId]="catalogCharTypeId"
					(onEntityRelationshipChange)="updateState($event)">
				</rl-module-association>
				<div class="mt-2 action-footer">
					<button (click)="cancel()" class="btn btn-light">Cancel</button>
					<button (click)="saveChanges(false)" class="btn btn-success ms-3">Done</button>
				</div>
			</ng-template>
		</rl-panel>
	</rl-panel-switcher>
</article>