import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Injector, Input, Output, Provider, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IPHBulkCreateRequest } from "rl-common/components/bulk-grid/bulk-grid.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ITemplateCharData } from "rl-common/services/entity/models/i-template-char-data";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { Subscription } from "rxjs";
import { BulkConfig, BulkCreateStrategy } from "../../../../common/components/bulk-grid/bulk-config.strategy";
import { ICreateAssociationEvent } from "../../../../common/components/new-association-modal/new-association-wizard/new-association.component.models";
import { CharTypeId } from "../../../../common/rl-common.consts";
import { LinkHelperService } from "../../../../common/services/link-helper.service";
import { OneConfigService } from "../../../../common/services/one-config/one-config.service";
import { ProgressService } from "../../../../common/services/progress.service";
import { RightsExplorerHttpService } from "../rights-explorer-http.service";
import { AvailsSnapshot } from "../rights-explorer.models";
import { RightsExplorerService } from "../rights-explorer.service";
import { AvailsBulkCreateRightsModalAdapter, IAvailsBulkCreateRightsComponent } from "./avails-bulk-create-rights.adapter";
import { ExistingOrNewDealComponent } from "./existing-or-new-deal/existing-or-new-deal.component";
import { IDealAssociation } from "./existing-or-new-deal/existing-or-new-deal.models";
import { SelectRightsTemplateComponent } from "./select-rights-template/select-rights-template.component";

@Component({
	selector: "rl-avails-bulk-create-rights",
	templateUrl: "./avails-bulk-create-rights.component.html",
	styleUrl: "./avails-bulk-create-rights.component.scss",
	imports: [ArchwizardModule, ExistingOrNewDealComponent, NgIf, SelectRightsTemplateComponent]
})
export class AvailsBulkCreateRightsComponent implements IAvailsBulkCreateRightsComponent {

	private subs: Subscription[] = [];

	@ViewChild(WizardComponent)
	wizard: WizardComponent;

	selectedRightsTemplate: ICharacteristicTemplate;

	@Input()
	avails: AvailsSnapshot[];

	@Output()
	onComplete = new EventEmitter<boolean>();

	charMetaData: ICharacteristicMetaDataCollection;

	deal: IDealAssociation;
	newDeal: ICreateAssociationEvent;

	config: BulkConfig;
	charData: ITemplateCharData[] = [];
	goToDeal: boolean = true;
	jobId: string;

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _progressService: ProgressService,
		private readonly linkHelper: LinkHelperService,
		private readonly router: Router,
		private readonly _rightsExplorerHttpService: RightsExplorerHttpService,
		private readonly _rightsExplorerService: RightsExplorerService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _injector: Injector) { }

	cancel() {
		this.onComplete.emit(false);
	}

	apply(deal: IDealAssociation) {
		this.deal = deal;
		this.wizard.goToNextStep();
	}

	create(event: ICreateAssociationEvent) {
		this.newDeal = event;
		//this.wizard.goToNextStep();
	}

	cancelRightsTemplate() {
		this.selectedRightsTemplate = null;
		this.wizard.goToPreviousStep();
	}

	selectRightsTemplate(template: ICharacteristicTemplate) {
		const metaData = this._oneConfig.getTemplateMetaData(CharTypeId.Right, template.templateID);
		this.charMetaData = metaData;
		this.selectedRightsTemplate = template;
		this.charData = this._rightsExplorerService.getCharDatas(this.selectedRightsTemplate.templateID, metaData, this.avails);
		this.configureBulkEdit();

		this.onComplete.emit(true);

		const sub = this._modalService.bulkEditRightsModal(this._injector, this.deal, template.templateID, this.config, this.charData)
			.subscribe();
		this.subs.push(sub);
	}

	configureBulkEdit() {
		const createStrategy = new BulkCreateStrategy()
			.withAvailsdRows(this.avails.length);

		this.config = new BulkConfig(CharTypeId.Right)
			.withBulkStrategy(createStrategy)
			.withTemplate(this.selectedRightsTemplate.templateID)
			.withParent(CharTypeId.Transaction, this.deal.recordId, this.deal.templateId)
			.withOnDone();
	}

	complete(isComplete: boolean) {
		if (isComplete && this.goToDeal) {
			this.goToDealRecord();
		}
		this.onComplete.emit(isComplete);
	}

	createComplete() {
		this.complete(true);
	}

	done(request: IPHBulkCreateRequest) {
		this._rightsExplorerHttpService.createAvailsRights(this.deal.recordId, request)
			.subscribe((jobId) => {
				this.jobId = jobId;
				this.wizard.goToNextStep();
			});
	}

	cancelBulkEdit() {
		this.complete(false);
	}

	goToDealRecord() {
		if (this.deal) {
			this._progressService.startProgress();
			// Honestly this timeout is probably not needed anymore, ask crabb if you want
			setTimeout(() => {
				this._progressService.endProgress();
				const url = this.linkHelper.go(CharTypeId.Transaction, this.deal.recordId);
				this.router.navigate(url);
			}, 2_000);
		}
	}
}

export const AVAILSBULKCREATERIGHTS_MODAL_PROVIDER: Provider = {
  provide: AvailsBulkCreateRightsModalAdapter,
  useFactory: () => new AvailsBulkCreateRightsModalAdapter(AvailsBulkCreateRightsComponent)
};

