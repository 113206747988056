import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, first } from "lodash";
import { ICharDataChangedEvent } from "rl-common/components/char-data/char-data.models";
import { AssociateContactsComponent } from "rl-common/components/create-workflow/associate-contacts.component";
import { ITemplateHierarchy } from "rl-common/components/create-workflow/create-entity.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IAssociatedRecords, INewEntityAssociation } from "rl-common/services/associations/association.models";
import { AssociationService } from "rl-common/services/associations/association.service";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { GrowlerService } from "rl-common/services/growler.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { SessionService } from "rl-common/services/session.service";
import { QueryUtil } from "rl-common/utils";
import { AclUtil } from "rl-common/utils/acl.util";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { of, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { v4 } from "uuid";
import { CharTypeNamePipe } from "../../../pipes/char-type-name.pipe";
import { PluralCharTypeNamePipe } from "../../../pipes/plural-char-type-name.pipe";
import { CreateAndAssociateHierarchicalRecordsComponent } from "../../create-workflow/create-and-associate-hierarchical-records/create-and-associate-hierarchical-records.component";
import { IDirectionChangedEvent } from "../new-association.models";
import { ContactService } from "./../../../services/contact/contact.service";
import { IParentEntity } from "./../../../services/entity/parent-entity/parent-entity.service";
import { IDUtil } from "./../../../utils/id.util";
import { GridDataSourceBuilder } from "./../../grid/datasource/builders/grid-datasource-builder";
import { CreateNewAssociationComponent } from "./create-new-association/create-new-association.component";
import { ModuleAssociationComponent } from "./module-association/module-association.component";
import { IAssociationEvent, ICreateAssociationEvent } from "./new-association.component.models";
import { AssociationChipsComponent } from "./select-associations/association-chips/association-chips.component";

export interface IAssociationsSelectedEvent {
	selected: IRecordTitle[];
}

export interface ITemporaryRecordTitle extends IRecordTitle {
	tempId: string;
}

@Component({
	selector: "rl-new-association",
	templateUrl: "./new-association.component.html",
	styleUrls: ["./new-association.component.scss"],
	providers: [ParentEntityService],
	imports: [NgbNav, NgIf, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, ModuleAssociationComponent, ArchwizardModule, CreateNewAssociationComponent, AssociationChipsComponent, ReactiveFormsModule, FormsModule, AssociateContactsComponent, CreateAndAssociateHierarchicalRecordsComponent, NgbNavOutlet, CharTypeNamePipe, PluralCharTypeNamePipe]
})
export class NewAssociationComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	parentAssociations: IAssociatedRecords;

	@Input()
	parentTemplateId: number;

	@Input()
	parentTitle: string;

	@Input()
	defaultSelectedIds: number[] = [];

	@Input()
	showExisting = true;

	@Input()
	shouldSave = true;

	@Input()
	isMultiple = true;

	@Input()
	partyId?: number;

	@Input()
	hasValidCreateTemplates: boolean;

	@Input()
	keywords: string;

	@Output()
	onCreate = new EventEmitter<ICreateAssociationEvent>();

	@Output()
	onApply = new EventEmitter<IAssociationEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	@Input()
	direction: RelationshipTypes;

	@Input()
	relationshipDirectionTypes: RelationshipTypes[];

	@Input()
	includeCurrentAssociations = false;

	@Input()
	isLocked = false;

	@ViewChild(WizardComponent)
	wizard: WizardComponent;

	@ViewChild(AssociateContactsComponent)
	associateContacts: AssociateContactsComponent;

	parentRecordId: number;
	parentCharTypeId: CharTypeId;

	newEntityDirection: RelationshipTypes;
	viewNewRecordAfterCreation = true;

	isSaving = false;
	isCreating = false;
	applyText: string;
	showContacts = false;
	createdEntityId: string;
	jobId: string;
	title: string;
	canAutoCreateHierarchy: boolean;
	childTemplates: ITemplateHierarchy[] = [];
	dataSource: AssocModuleSelectDataSource<IEntitySearchDoc, IEntitySearchDoc>;
	documentCharType = CharTypeId.Document;

	newRecordTitle: ITemporaryRecordTitle = null;
	public charTypeDisplayName: string;
	public charTypeDisplayNamePlural: string;
	state: IEntityRelationshipState<number, IEntitySearchDoc>;

	public form: UntypedFormGroup;

	private readonly _subscriptions: Subscription[] = [];

	get isValid() {
		if (!this.dataSource) {
			return false;
		}
		const state = this.dataSource.dataSelectStrategy.selectedState;
		let gridCount = 0;
		if (!state.isAllSelected) {
			gridCount = state.selectedIds.size;
		} else {
			const count = this.parentAssociations.count;
			gridCount = this.dataSource.rowCount$.value - state.deselectedIds.size;
		}
		if (gridCount <= 0 && this.state?.inputListSelected?.length <= 0 && !this.newAssociationTemplate) {
			return false;
		}
		if (this.newAssociationTemplate && (!this.form.valid || !this.form.dirty)) {
			return false;
		}

		return true;
	}

	get newAssociationTemplate() {
		return this.form.get("newAssociationTemplate").value as ICharacteristicTemplate;
	}

	get newAssociationCharData() {
		return this.form.get("newAssociationCharData").value as ICharDataChangedEvent;
	}

	private validCharData = (c: UntypedFormControl) => {
		if (!c.value) {
			return { charDataNull: true };
		}
	};

	constructor(
		private readonly _associationService: AssociationService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _entityService: EntityService,
		private readonly _contactService: ContactService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _router: Router,
		private readonly cd: ChangeDetectorRef,
		private readonly _linkHelper: LinkHelperService,
		private readonly _sessionService: SessionService,
		private readonly _growler: GrowlerService
	) { }

	ngOnInit() {
		this.parentRecordId = first(this.parentAssociations.recordIds);
		this.parentCharTypeId = this.parentAssociations.charTypeId;
		this._parentEntityService.initializeParent({
			recordId: this.parentRecordId,
			charTypeId: this.parentCharTypeId,
			templateId: this.parentTemplateId,
			partyId: this.partyId
		});
		this.newEntityDirection = this.direction;
		this.applyText = this.shouldSave ? "Save" : "Apply";
		const noContactCharTypes = [CharTypeId.User, CharTypeId.Right, CharTypeId.Amount, CharTypeId.Usage, CharTypeId.Document];
		this.showContacts = !noContactCharTypes.includes(this.charTypeId);
		this.buildForm();

		let obs = null;
		const acls = this._sessionService.acls;
		if (this.charTypeId === CharTypeId.User && this._parentEntityService.partyId) {
			// TODO: Move party templates to one config.
			obs = this._contactService.getPartyTemplates(this._parentEntityService.charTypeId, this._parentEntityService.templateId, [this._parentEntityService.partyId])
				.pipe(map(partyDict => {
					const templateIds = partyDict[this._parentEntityService.partyId];
					const filterteredTemplateIds = templateIds.filter(template => AclUtil.hasWriteAccess(acls, template.acl)).map(t => t.templateID);
					this.buildDataSource$(filterteredTemplateIds);
				}));
		} else {
			obs = this.buildDataSource$();
		}
		this._subscriptions.push(obs.subscribe());
		this.cd.detectChanges();
	}

	buildDataSource$(templateIds: number[] = null) {
		const parent: IParentEntity = {
			charTypeId: this.parentCharTypeId,
			recordId: this.parentRecordId,
			title: this.parentTitle,
			templateId: this.parentTemplateId
		};
		this.dataSource = this._gridDataSourceBuilder.assocModuleSearchDataSource(this.charTypeId, parent, this.direction, this.includeCurrentAssociations, this.defaultSelectedIds,
			this.isLocked, this.isMultiple, templateIds, this._parentEntityService.partyId);
		return this.dataSource.indexedRowData$
			.pipe(
				filter((ird) => !!ird),
				take(1),
				tap((initialRowData) => {
					const dataSelectStrategy = this.dataSource.dataSelectStrategy;
					if (!this.defaultSelectedIds) {
						return;
					}

					if (dataSelectStrategy.selectAllEnabled && this.dataSource.rowCount$.value !== 0 && this.defaultSelectedIds.length === this.dataSource.rowCount$.value) {
						dataSelectStrategy.selectAll();
					} else {
						const selectedRowData = initialRowData.filter(rd => this.defaultSelectedIds.includes(rd.data.recordID));
						if (selectedRowData.length > 0) {
							this.dataSource.dataSelectStrategy.selectRows(selectedRowData);
						}
					}
				})
			);
	}

	buildForm() {
		const newAssociationTemplateFormControl = new UntypedFormControl(null);
		const newCharDataControl = new UntypedFormControl(null, [this.validCharData]);
		const onlyShowRequiredControl = new UntypedFormControl(false);
		this.form = this._formBuilder.group({
			newAssociationTemplate: newAssociationTemplateFormControl,
			newAssociationCharData: newCharDataControl,
			onlyShowRequired: onlyShowRequiredControl
		});

		const newAssociationSub = newAssociationTemplateFormControl.valueChanges.subscribe(value => {
			this.newAssociationTemplateChanged();
		});

		this._subscriptions.push(newAssociationSub);
	}

	setNewEntityDirection(event: IDirectionChangedEvent) {
		this.newEntityDirection = event.direction;
	}

	private newAssociationTemplateChanged() {
		const newAssociationTemplate = this.form.get("newAssociationTemplate").value;

		if (!newAssociationTemplate) {
			this.newRecordTitle = null;
			const control = this.form.get("newAssociationCharData") as FormControl<ICharDataChangedEvent>;
			control.setValue({ isValid: false, charData: [], alerts: [] });
			return;
		}

		const directionLabel = RelationshipTypes[this.newEntityDirection];
		this.newRecordTitle = {
			recordId: -1,
			title: `New ${directionLabel} ${CharTypeIdUtil.toDisplayName(this.charTypeId)}`,
			tempId: v4()
		};

		this.canAutoCreateHierarchy = false;
		if (this.charTypeId === CharTypeId.Property) {
			const templates = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.newAssociationTemplate.templateID, this.charTypeId);
			this.canAutoCreateHierarchy = templates && templates.length > 0;
			this.childTemplates = templates.map(x => ({
				directParentTemplates: [this.newAssociationTemplate.templateID],
				templateID: x.templateID,
				templateLabel: x.templateName,
				childTemplates: null,
				level: 1,
				numberOfRecords: 0
			}));
		}
	}

	cancel() {
		this.onCancel.emit();
	}

	setSelected(event: IAssociationsSelectedEvent) {
		const selectedRecordIds = event.selected.map(s => s.recordId);
		const rows = this.dataSource.indexedRowData$.value.filter(rd => selectedRecordIds.includes(rd.data.recordID));
		this.dataSource.dataSelectStrategy.selectRows(rows);
	}

	folderUploaded(jobId: string) {
		this.jobId = jobId;
		this.emitCreate();
	}

	emitCreate() {
		this.isSaving = false;
		this.isCreating = false;
		this.onCreate.emit({ jobId: this.jobId, title: this.title });
	}

	removeNewRel() {
		this.form.reset();
		this.newRecordTitle = null;
	}

	continueToNextStep() {
		this.wizard.goToNextStep();
	}

	buildModelFromSelection(relationshipState: IEntityRelationshipState<number, IEntitySearchDoc>, filterQueries: IQueryNode[], query: IQueryNode): ISearchRequestModel {
		if (!relationshipState) {
			return null;
		}
		const gridSelectState = relationshipState.gridSelected;
		query = cloneDeep(query);
		filterQueries = cloneDeep(filterQueries);
		if (!gridSelectState.isAllSelected && gridSelectState.selectedIds.size === 0 && this.state?.inputListSelected.length === 0) {
			return null; // nothing selected, return null
		}

		const inputListIds = relationshipState?.inputListSelected ? relationshipState?.inputListSelected.map(r => r.recordID) : [];
		let selectedRowCount = 0;
		if (gridSelectState.isAllSelected) {
			const deselected = Array.from(gridSelectState.deselectedIds) as number[];
			if (deselected.length > 0) {
				const notDeselected = QueryUtil.$not(QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, deselected));
				filterQueries.push(notDeselected);
			}
			if (this.dataSource.selectableInnerQuery) {
				filterQueries.push(this.dataSource.selectableInnerQuery);
			}
			selectedRowCount = this.dataSource.availableRowCount$.value - deselected.length;
		} else {
			filterQueries = [];
			const gridSelectedIds = Array.from(gridSelectState.selectedIds) as number[];
			const ids = [...gridSelectedIds, ...inputListIds];
			const selected = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, ids);
			filterQueries.push(selected);
			selectedRowCount = ids.length;
		}
		return {
			query,
			keywords: gridSelectState.isAllSelected ? this.dataSource.keywords : null,
			filterQueries,
			rows: selectedRowCount,
			start: 0,
		};
	}

	updateState(state: IEntityRelationshipState<number, IEntitySearchDoc>) {
		this.state = state;
	}

	apply(templates: ITemplateHierarchy[] = []) {
		if (!this.isValid) {
			return;
		}
		this.isSaving = true;
		const filterQueries = this.dataSource.buildFilterQueries();
		const query = this.dataSource.query;
		const model = this.buildModelFromSelection(this.state, filterQueries, query,);
		const associations: IAssociatedRecords = {
			charTypeId: this.charTypeId,
			recordIds: null,
			count: this.dataSource.rowCount$.value,
			model
		};

		let newEntity: INewEntityAssociation = null;
		const newAssociationTemplate = this.newAssociationTemplate;
		const newAssociationCharData = this.newAssociationCharData;
		let contactPartyGroups = null;
		let newContacts = null;
		const state = this.dataSource.dataSelectStrategy.selectedState as GridSelectState<number, IGridViewAssocEntityRec>;
		const selectedCount = state.isAllSelected ? this.dataSource.rowCount$.value - state.deselectedIds.size : state.selectedIds.size;
		const newEntityEntered = newAssociationTemplate && newAssociationCharData;
		const recordWasEnteredBefore = this.defaultSelectedIds.length > 0;

		if ((this.dataSource.dataSelectStrategy.selectType === GridSelectType.Radio && (selectedCount > 0 && (recordWasEnteredBefore || newEntityEntered)))) {
			return this._modalService.alert("Alert", "Only one contact is allowed on this party.").subscribe(() => {
				this.isSaving = false;
			});
		} else {
			if (newEntityEntered) {
				if (this.dataSource.dataSelectStrategy.selectType === GridSelectType.Radio && selectedCount > 0) {
					this.isSaving = false;
				}
				newEntity = {
					charTypeId: this.charTypeId,
					templateId: newAssociationTemplate.templateID,
					charData: newAssociationCharData.charData,
					tempId: this.newRecordTitle.tempId,
					tempTitle: this.newRecordTitle.title,
					alerts: newAssociationCharData.alerts
				};
				if (this.associateContacts) {
					const contactsForNewEntityRequest = this.associateContacts.getContactsForNewEntityRequest();
					contactPartyGroups = contactsForNewEntityRequest.contactPartyGroups;
					newContacts = contactsForNewEntityRequest.newContacts;
				}
			}
			if (!this.shouldSave) {
				if (state.isAllSelected) {
					// TODO: Fix this when we implement select all on contact associations.
					console.warn("Emitting selected associations when select all is enabled is not implemented. ");
				}
				const filterQueries = this.dataSource.buildFilterQueries();
				const query = this.dataSource.query;
				const model = this.buildModelFromSelection(this.state, filterQueries, query);
				this.onApply.emit({
					selectedModel: model,
					selectedState: state,
					selectedCount,
					newEntity,
				});
				return;
			}

			this._associationService.createAssociations(
				this.parentAssociations,
				associations,
				this.dataSource.direction,
				newEntity,
				this.newEntityDirection,
				contactPartyGroups,
				newContacts
			).pipe(
				map((result) => {
					this.jobId = result.jobId;
					this.title = result.title;
					this.createdEntityId = result.createdEntityId;
					return result;
				}),
				switchMap(result => this._modalService.jobProgress(result.jobId, result.invalidRelationships.length > 0, undefined, undefined, undefined, undefined, undefined, result.invalidRelationships)),
				switchMap((result) => {
					if (!!result && this.createdEntityId !== undefined && templates.length > 0) {
						return this._entityService.createAndAssociateHierarchicalRecords(this.createdEntityId, templates);
					}
					return of(null);
				}),
				catchError(error => {
					const statusCode = error.status;
					if (statusCode < 500) {
						this._growler.error().growl(error?.error?.message ?? `Association Failed`);
						this.cancel();
					}
					throw error;
				}),
			).subscribe(() => {
				if (this.viewNewRecordAfterCreation && this.createdEntityId) {
					const split = IDUtil.splitEntityID(this.createdEntityId);
					const charTypeId = split.charTypeID;
					const recordId = split.recID;
					const route = this._linkHelper.go(charTypeId, recordId);
					this._router.navigate(route);
				}
				this.emitCreate();
			});
		}
	}

	goToPreviousWizardStep() {
		this.wizard.goToPreviousStep();
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	onHierarchyCreated() {
		this.emitCreate();
	}
}
