<div class="m-3">
	<h1 class="mb-4">Font Styles</h1>
	<ul ngbNav #nav="ngbNav" class="nav-tabs">
		<li ngbNavItem>
			<a ngbNavLink class="font-calibri">
				Calibri (14px)
			</a>
			<ng-template ngbNavContent>
				<div class="font-calibri">
					<h3>Calibri</h3>
					<p><strong>Examples of Calibri Font Family</strong><br>Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
					</p>
					<p class="mb-4"><em>Integer sed porta mi. Suspendisse quam nisl, tempor et tellus a, dapibus
							placerat nisi.</em>
						Duis iaculis sagittis
						scelerisque. Donec vitae faucibus sapien, sit amet condimentum orci. Quisque sed lectus eget
						ante pellentesque tristique
						ac et sapien. Nulla lobortis sit amet ante a varius. Maecenas lacus erat, varius nec interdum
						in, rutrum at nisl.
						Quisque aliquam luctus leo, accumsan semper ante interdum nec. Cras pulvinar, dui sit amet
						posuere facilisis, diam metus
						convallis massa, at gravida metus diam non turpis. Praesent dignissim eros non ipsum
						pellentesque ullamcorper. Donec
						risus est, blandit eget venenatis eu, porttitor in augue. Donec suscipit mi vel tincidunt
						viverra. Sed vel metus sed
						justo dapibus finibus. Pellentesque vulputate, eros et pretium maximus, nisl mauris euismod
						ligula, ac tristique dolor
						tortor tincidunt ante. Quisque massa urna, hendrerit nec lacus et, bibendum tempus libero.
					</p>
					<div class="rounded-panel bg-light my-4 p-4">
						<h4>Lorem ipsum dolor sit amet, consectetur</h4>
						Adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
						<div class="mt-4 d-flex">
							<div class="mx-2">
								<label class="fw-bold">Deals:</label><br>
								<select class="form-select">
									<option>Select Deal</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Catalog Items:</label><br>
								<select class="form-select">
									<option>Select Catalog Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Inventory Items:</label><br>
								<select class="form-select">
									<option>Select Inventory Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Templates:</label><br>
								<select class="form-select">
									<option>Select Template</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<button class="btn btn btn-light me-3">Cancel</button>
							<button class="btn btn btn-info me-3">Secondary Option</button>
							<button class="btn btn btn-success">Save Changes</button>
						</div>
					</div>
					<div class="mt-3 mb-2 d-flex justify-content-end align-items-center">
						<div class="rl-grid__filter--search me-auto">
							<div class="input-group">
								<input type="text" class="form-control form-control-sm" placeholder="Search">
								<button class="btn btn-icon btn-sm btn-info border-bottom">
									<i class="far fa-search"></i>
								</button>
							</div>
						</div>
						<div [autoClose]="true" ngbDropdown placement="bottom-right">
							<button type="button" class="btn btn-sm btn-link me-2" ngbDropdownToggle
								role="button">Example Dropdown Menu</button>
							<div ngbDropdownMenu class="p-2" style="width:250px;" data-cy="DropdownMenuRecordType">
								<h6 class="dropdown-header">Heading</h6>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<h6 class="dropdown-header">Alternative Heading</h6>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
							</div>
						</div>
						<div class="panel__ops ms-3">
							<button class="btn btn-icon btn-success">
								<i class="far fa-plus"></i>
							</button>
						</div>

					</div>
					<div class="card-body">
						<table class="table rl-table table-hover">
							<thead>
								<tr>
									<th>ID</th>
									<th class="text-nowrap">Record Type</th>
									<th class="text-nowrap">Source Field Name</th>
									<th class="text-nowrap">Tag Label</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr class="no-items-message">
									<td colspan="5" class="border-0">
										<div class="d-flex align-items-center justify-content-center pt-3">
											<i class="far fa-th-list"></i>
											<h5 class="mb-0 ms-3">No fields found.</h5>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink class="font-ibm-plex-sans">
				IBM Plex Sans (13px)
			</a>
			<ng-template ngbNavContent>
				<div class="font-ibm-plex-sans">
					<h3>IBM Plex Sans</h3>
					<p><strong>Examples of IBM Plex Sans Font Family</strong><br>Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
					</p>
					<p class="mb-4"><em>Integer sed porta mi. Suspendisse quam nisl, tempor et tellus a, dapibus
							placerat nisi.</em>
						Duis iaculis sagittis
						scelerisque. Donec vitae faucibus sapien, sit amet condimentum orci. Quisque sed lectus eget
						ante pellentesque tristique
						ac et sapien. Nulla lobortis sit amet ante a varius. Maecenas lacus erat, varius nec interdum
						in, rutrum at nisl.
						Quisque aliquam luctus leo, accumsan semper ante interdum nec. Cras pulvinar, dui sit amet
						posuere facilisis, diam metus
						convallis massa, at gravida metus diam non turpis. Praesent dignissim eros non ipsum
						pellentesque ullamcorper. Donec
						risus est, blandit eget venenatis eu, porttitor in augue. Donec suscipit mi vel tincidunt
						viverra. Sed vel metus sed
						justo dapibus finibus. Pellentesque vulputate, eros et pretium maximus, nisl mauris euismod
						ligula, ac tristique dolor
						tortor tincidunt ante. Quisque massa urna, hendrerit nec lacus et, bibendum tempus libero.
					</p>
					<div class="rounded-panel bg-light my-4 p-4">
						<h4>Lorem ipsum dolor sit amet, consectetur</h4>
						Adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
						<div class="mt-4 d-flex">
							<div class="mx-2">
								<label class="fw-bold">Deals:</label><br>
								<select class="form-select">
									<option>Select Deal</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Catalog Items:</label><br>
								<select class="form-select">
									<option>Select Catalog Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Inventory Items:</label><br>
								<select class="form-select">
									<option>Select Inventory Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Templates:</label><br>
								<select class="form-select">
									<option>Select Template</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<button class="btn btn btn-light me-3">Cancel</button>
							<button class="btn btn btn-info me-3">Secondary Option</button>
							<button class="btn btn btn-success">Save Changes</button>
						</div>
					</div>
					<div class="mt-3 mb-2 d-flex justify-content-end align-items-center">
						<div class="rl-grid__filter--search me-auto">
							<div class="input-group">
								<input type="text" class="form-control form-control-sm" placeholder="Search">
								<button class="btn btn-icon btn-sm btn-info border-bottom">
									<i class="far fa-search"></i>
								</button>
							</div>
						</div>
						<div [autoClose]="true" ngbDropdown placement="bottom-right">
							<button type="button" class="btn btn-sm btn-link me-2" ngbDropdownToggle
								role="button">Example Dropdown
								Menu</button>
							<div ngbDropdownMenu class="p-2" style="width:250px;" data-cy="DropdownMenuRecordType">
								<h6 class="dropdown-header">Heading</h6>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<h6 class="dropdown-header">Alternative Heading</h6>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
							</div>
						</div>
						<div class="panel__ops ms-3">
							<button class="btn btn-icon btn-success">
								<i class="far fa-plus"></i>
							</button>
						</div>

					</div>
					<div class="card-body">
						<table class="table rl-table table-hover">
							<thead>
								<tr>
									<th>ID</th>
									<th class="text-nowrap">Record Type</th>
									<th class="text-nowrap">Source Field Name</th>
									<th class="text-nowrap">Tag Label</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr class="no-items-message">
									<td colspan="5" class="border-0">
										<div class="d-flex align-items-center justify-content-center pt-3">
											<i class="far fa-th-list"></i>
											<h5 class="mb-0 ms-3">No fields found.</h5>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink class="font-roboto">
				Roboto (13px)
			</a>
			<ng-template ngbNavContent>
				<div class="font-roboto">
					<h3>Roboto</h3>
					<p><strong>Examples of IBM Plex Sans Font Family</strong><br>Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
					</p>
					<p class="mb-4"><em>Integer sed porta mi. Suspendisse quam nisl, tempor et tellus a, dapibus
							placerat nisi.</em>
						Duis iaculis sagittis
						scelerisque. Donec vitae faucibus sapien, sit amet condimentum orci. Quisque sed lectus eget
						ante pellentesque tristique
						ac et sapien. Nulla lobortis sit amet ante a varius. Maecenas lacus erat, varius nec interdum
						in, rutrum at nisl.
						Quisque aliquam luctus leo, accumsan semper ante interdum nec. Cras pulvinar, dui sit amet
						posuere facilisis, diam metus
						convallis massa, at gravida metus diam non turpis. Praesent dignissim eros non ipsum
						pellentesque ullamcorper. Donec
						risus est, blandit eget venenatis eu, porttitor in augue. Donec suscipit mi vel tincidunt
						viverra. Sed vel metus sed
						justo dapibus finibus. Pellentesque vulputate, eros et pretium maximus, nisl mauris euismod
						ligula, ac tristique dolor
						tortor tincidunt ante. Quisque massa urna, hendrerit nec lacus et, bibendum tempus libero.
					</p>
					<div class="rounded-panel bg-light my-4 p-4">
						<h4>Lorem ipsum dolor sit amet, consectetur</h4>
						Adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
						<div class="mt-4 d-flex">
							<div class="mx-2">
								<label class="fw-bold">Deals:</label><br>
								<select class="form-select">
									<option>Select Deal</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Catalog Items:</label><br>
								<select class="form-select">
									<option>Select Catalog Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Inventory Items:</label><br>
								<select class="form-select">
									<option>Select Inventory Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Templates:</label><br>
								<select class="form-select">
									<option>Select Template</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<button class="btn btn btn-light me-3">Cancel</button>
							<button class="btn btn btn-info me-3">Secondary Option</button>
							<button class="btn btn btn-success">Save Changes</button>
						</div>
					</div>
					<div class="mt-3 mb-2 d-flex justify-content-end align-items-center">
						<div class="rl-grid__filter--search me-auto">
							<div class="input-group">
								<input type="text" class="form-control form-control-sm" placeholder="Search">
								<button class="btn btn-icon btn-sm btn-info border-bottom">
									<i class="far fa-search"></i>
								</button>
							</div>
						</div>
						<div [autoClose]="true" ngbDropdown placement="bottom-right">
							<button type="button" class="btn btn-sm btn-link me-2" ngbDropdownToggle
								role="button">Example Dropdown
								Menu</button>
							<div ngbDropdownMenu class="p-2" style="width:250px;" data-cy="DropdownMenuRecordType">
								<h6 class="dropdown-header">Heading</h6>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<h6 class="dropdown-header">Alternative Heading</h6>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
							</div>
						</div>
						<div class="panel__ops ms-3">
							<button class="btn btn-icon btn-success">
								<i class="far fa-plus"></i>
							</button>
						</div>

					</div>
					<div class="card-body">
						<table class="table rl-table table-hover">
							<thead>
								<tr>
									<th>ID</th>
									<th class="text-nowrap">Record Type</th>
									<th class="text-nowrap">Source Field Name</th>
									<th class="text-nowrap">Tag Label</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr class="no-items-message">
									<td colspan="5" class="border-0">
										<div class="d-flex align-items-center justify-content-center pt-3">
											<i class="far fa-th-list"></i>
											<h5 class="mb-0 ms-3">No fields found.</h5>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink class="font-open-sans">
				Open Sans (Original)
			</a>
			<ng-template ngbNavContent>
				<div class="font-open-sans">
					<h3>Open Sans</h3>
					<p><strong>Examples of IBM Plex Sans Font Family</strong><br>Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
					</p>
					<p class="mb-4"><em>Integer sed porta mi. Suspendisse quam nisl, tempor et tellus a, dapibus
							placerat nisi.</em>
						Duis iaculis sagittis
						scelerisque. Donec vitae faucibus sapien, sit amet condimentum orci. Quisque sed lectus eget
						ante pellentesque tristique
						ac et sapien. Nulla lobortis sit amet ante a varius. Maecenas lacus erat, varius nec interdum
						in, rutrum at nisl.
						Quisque aliquam luctus leo, accumsan semper ante interdum nec. Cras pulvinar, dui sit amet
						posuere facilisis, diam metus
						convallis massa, at gravida metus diam non turpis. Praesent dignissim eros non ipsum
						pellentesque ullamcorper. Donec
						risus est, blandit eget venenatis eu, porttitor in augue. Donec suscipit mi vel tincidunt
						viverra. Sed vel metus sed
						justo dapibus finibus. Pellentesque vulputate, eros et pretium maximus, nisl mauris euismod
						ligula, ac tristique dolor
						tortor tincidunt ante. Quisque massa urna, hendrerit nec lacus et, bibendum tempus libero.
					</p>
					<div class="rounded-panel bg-light my-4 p-4">
						<h4>Lorem ipsum dolor sit amet, consectetur</h4>
						Adipiscing elit. Nulla vitae magna dapibus, sagittis sapien nec, consequat diam.
						Morbi aliquam fringilla leo, <em>sit amet rhoncus augue finibus in</em>. Nunc id tellus eget
						ligula tempor sodales ut non mi.
						Aenean metus justo, vestibulum vitae vehicula a, semper at ante. Nullam eu urna interdum, tempor
						elit in, volutpat
						felis. Cras lacus libero, condimentum nec orci in, porttitor vulputate odio. Mauris et
						consectetur mauris. Vestibulum a
						mollis lacus. Aliquam dapibus mi eget risus efficitur tincidunt. Pellentesque facilisis ut quam
						in pulvinar. Phasellus
						vehicula cursus ante vitae mattis. Aliquam pulvinar justo lectus, faucibus luctus diam tempor
						sed.
						<div class="mt-4 d-flex">
							<div class="mx-2">
								<label class="fw-bold">Deals:</label><br>
								<select class="form-select">
									<option>Select Deal</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Catalog Items:</label><br>
								<select class="form-select">
									<option>Select Catalog Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Inventory Items:</label><br>
								<select class="form-select">
									<option>Select Inventory Item</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
							<div class="mx-2">
								<label class="fw-bold">Templates:</label><br>
								<select class="form-select">
									<option>Select Template</option>
									<option>Another Item</option>
									<option>Second Item</option>
									<option>Final Selection</option>
								</select>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<button class="btn btn btn-light me-3">Cancel</button>
							<button class="btn btn btn-info me-3">Secondary Option</button>
							<button class="btn btn btn-success">Save Changes</button>
						</div>
					</div>
					<div class="mt-3 mb-2 d-flex justify-content-end align-items-center">
						<div class="rl-grid__filter--search me-auto">
							<div class="input-group">
								<input type="text" class="form-control form-control-sm" placeholder="Search">
								<button class="btn btn-icon btn-sm btn-info border-bottom">
									<i class="far fa-search"></i>
								</button>
							</div>
						</div>
						<div [autoClose]="true" ngbDropdown placement="bottom-right">
							<button type="button" class="btn btn-sm btn-link me-2" ngbDropdownToggle
								role="button">Example Dropdown
								Menu</button>
							<div ngbDropdownMenu class="p-2" style="width:250px;" data-cy="DropdownMenuRecordType">
								<h6 class="dropdown-header">Heading</h6>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<button ngbDropdownItem>Menu Item</button>
								<h6 class="dropdown-header">Alternative Heading</h6>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
								<button ngbDropdownItem>Alternative Menu Item</button>
							</div>
						</div>
						<div class="panel__ops ms-3">
							<button class="btn btn-icon btn-success">
								<i class="far fa-plus"></i>
							</button>
						</div>

					</div>
					<div class="card-body">
						<table class="table rl-table table-hover">
							<thead>
								<tr>
									<th>ID</th>
									<th class="text-nowrap">Record Type</th>
									<th class="text-nowrap">Source Field Name</th>
									<th class="text-nowrap">Tag Label</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr class="no-items-message">
									<td colspan="5" class="border-0">
										<div class="d-flex align-items-center justify-content-center pt-3">
											<i class="far fa-th-list"></i>
											<h5 class="mb-0 ms-3">No fields found.</h5>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
								<tr>
									<td>1234</td>
									<td>Example Record Title</td>
									<td>
										<a href="#">Source Field Name</a>
									</td>
									<td>Tag Label</td>
									<td class="p-1">
										<div class="hover-icons text-end">
											<button class="btn btn-icon btn-remove btn-sm me-1"><i
													class="far fa-trash-alt fa-sm"></i></button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>