<div class="modal-header" data-cy="AdvancedSearchModalHeader">
	<h2 class="modal-title">
		Advanced Search
		<span class="btn-icon btn-info btn-sm ms-1 cursor-pointer" data-pendo="tip-advanced-search" role="button"
			aria-label="Tooltip"><i class="far fa-question-circle"></i></span>
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="AdvancedSearchClose">
		<i class="far fa-times"></i>
	</button>
</div>
<form [formGroup]="form" (ngSubmit)="applySearch()" data-cy="AdvancedSearchModalForm">
	<div class="modal-body" data-cy="AdvancedSearchModalBody">
		<rl-advanced-search-builder [form]="form" [charTypeId]="charTypeId" [parentCharTypeId]="parentCharTypeId"
			[parentTemplateId]="parentTemplateId" data-cy="RlAdvancedSearchSearchBuilder"></rl-advanced-search-builder>
	</div>
	<div class="modal-footer d-flex advanced-modal--footer" [ngSwitch]="isModify" data-cy="AdvancedSearchModalFooter">
		<ng-container *ngSwitchCase="true">
			<div class="me-auto d-flex">
				<strong class="mt-1 me-2 text-nowrap">Edit Name:</strong>
				<input class="form-control" type="text" placeholder="Save As" formControlName="searchName"
					data-cy="EnterNameForSaveTextbox">
				<span class="mt-1 px-3">OR</span>
				<strong class="mt-1 me-2 text-nowrap">Save As:</strong>
				<input class="form-control me-2 search-name" type="text" placeholder="Enter Name"
					formControlName="saveAsName" data-cy="EnterNewSavedSearchNameTextbox">
			</div>

			<div class="form-check">
				<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="displayOnDashboard"
					data-cy="DisplayOnDashboardCheckbox">
				<label class="form-check-label"><strong>Display on Dashboard</strong></label>
			</div>
			<div class="form-check ms-3 align-self-start mt-3" *ngIf="createGlobalPermissions">
				<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="global"
					data-cy="GlobalAdvancedSearchCheckbox">
				<label class="form-check-label text-nowrap"><strong>Share this with everyone</strong><br>
					<em>(Note: All fields may not be visible for all users due to permissions)</em></label>
			</div>

			<button type="button" class="btn btn-light ms-5" (click)="cancel()"
				data-cy="AdvancedSearchCancel">Cancel</button>
			<button type="button" class="btn btn-success ms-3" [disabled]="!form.valid" (click)="applySearch()"
				data-cy="AdvancedSearchApply">Apply</button>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<div class="me-auto d-flex">
				<strong class="mt-1 me-2 text-nowrap">Save As:</strong>
				<input class="form-control" type="text" placeholder="Enter Name" formControlName="searchName"
					data-cy="EnterNameForSaveTextbox">
			</div>

			<div class="form-check">
				<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="displayOnDashboard"
					data-cy="DisplayOnDashboardCheckbox">
				<label class="form-check-label"><strong>Display on Dashboard</strong></label>
			</div>
			<div class="form-check ms-3 align-self-start mt-3" *ngIf="createGlobalPermissions">
				<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="global"
					data-cy="GlobalAdvancedSearchCheckbox">
				<label class="form-check-label text-nowrap"><strong>Share this with everyone</strong><br>
					<em>(Note: All fields may not be visible for all users due to permissions)</em></label>
			</div>

			<button type="button" class="btn btn-light ms-5" (click)="cancel()"
				data-cy="AdvancedSearchCancel">Cancel</button>
			<button type="button" class="btn btn-success ms-3" [disabled]="!form.valid" (click)="applySearch()"
				data-cy="AdvancedSearchApply">Apply</button>
		</ng-container>
	</div>

</form>