<rl-address-edit [recordId]="recordId" [template]="template" [charData]="charData" (charDataChange)="updateWorkingCharData($event)"></rl-address-edit>
<div class="modal-footer" data-cy="ModalFooter">
	<button class="btn btn-light ms-3" (click)="cancel()"
		data-cy="ModalCancelButton">Cancel</button>
	<button class="btn ms-3 btn-info" [disabled]="isSaving" (click)="save()" 	data-cy="ModalSaveButton">
		@if(isSaving) {
		<i class="far fa-spinner fa-spin me-2" ></i>
		}
		Save
	</button>
</div>