import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICharDataChangedEvent } from "rl-common/components/char-data/char-data.models";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { CharDataTableComponent } from "../../../char-data/char-data-table.component";

@Component({
	selector: "rl-address-edit",
	imports: [CharDataTableComponent],
	templateUrl: "./address-edit.component.html",
	styleUrl: "./address-edit.component.scss"
})
export class AddressEditComponent {
	@Input()
	recordId: number;

	@Input()
	template: ICharacteristicMetaDataCollection

	@Input()
	charData: ICharacteristicData[];

	@Output()
	charDataChange = new EventEmitter<ICharDataChangedEvent>();

	updateWorkingCharData(updatedCharData: ICharDataChangedEvent) {
		this.charDataChange.emit(updatedCharData);
	}
}
