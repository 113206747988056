
export interface IFinancialPeriod {
	id: number;
	year: number;
	period: number;
	startDate: string;
	endDate: string;
	status: FinancialPeriodStatus;
	sid: number;
}

export enum FinancialPeriodStatus {
	Open,
	Locked
}

export interface IClosedPeriodSettings {
	availableActions: [number[]];
	selectedActions: number[];
}
