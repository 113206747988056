import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { SessionService } from "rl-common/services/session.service";
import { PromptModelComponent } from "../../mod-details-body/side-panel/prompt-model/prompt-model.component";
import { IViewMode } from "rl-common/services/chat/chat.models";

@Component({
    selector: "rl-deal-insights",
    templateUrl: "./deal-insights.component.html",
    styleUrls: ["./deal-insights.component.scss"],
    imports: [PromptModelComponent]
})

export class DealInsightsComponent implements OnInit {

	viewMode: IViewMode = IViewMode.dealInsights;

	constructor(
		private browserTitle: Title,
		public route: ActivatedRoute,
		private readonly sessionService: SessionService) { }

	ngOnInit() {
		this.browserTitle.setTitle("Intelligence Center");

	}

}