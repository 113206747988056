import { CurrencyPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NumberUtil } from "rl-common/utils/number.util";
import { LocaleDatePipe } from "../../../../pipes/locale-date.pipe";
import { IAllocatedAmount } from "../../financial.models";

@Component({
	selector: "rl-allocation-spread",
	templateUrl: "./allocation-spread.component.html",
	styleUrls: ["./allocation-spread.component.scss"],
	imports: [NgFor, ReactiveFormsModule, FormsModule, NgIf, LocaleDatePipe, CurrencyPipe]
})
export class AllocationSpreadComponent {

	constructor() { }

	@Input()
	allocatedAmounts: IAllocatedAmount[];

	@Input()
	parentAmount: number;

	@Output()
	onAmountChange = new EventEmitter<IAllocatedAmount[]>();

	toServerAmount(value: string) {
		const unmasked = NumberUtil.parseLocaleNumber(value);
		if (unmasked !== "" && isNaN(unmasked)) {
			return NumberUtil.maskLocaleNumber(value);
		}
		return NumberUtil.maskLocaleNumber(unmasked.toString());
	}

	updatePercentage(amount: string, allocatedAmount: IAllocatedAmount) {
		const fee = parseFloat(amount.replace(/,/g, ""));
		const percentageAmount = isNaN(fee) ? 0 : +(100 * fee / this.parentAmount).toFixed(2);
		this.updateAmount(fee, percentageAmount, allocatedAmount);
	}

	updateFee(percentage: number, allocatedAmount: IAllocatedAmount) {
		const feeAmount = isNaN(percentage) ? 0 : this.toServerAmount((this.parentAmount * (percentage / 100)).toString());
		this.updateAmount(+feeAmount, percentage, allocatedAmount);
	}

	updateAmount(amount: number, percentage: number, allocatedAmount: IAllocatedAmount) {
		this.allocatedAmounts.find(x => x.sourceCharTypeId === allocatedAmount.sourceCharTypeId && x.sourceRecordId === allocatedAmount.sourceRecordId).amount = isNaN(amount) ? 0 : amount;
		this.allocatedAmounts.find(x => x.sourceCharTypeId === allocatedAmount.sourceCharTypeId && x.sourceRecordId === allocatedAmount.sourceRecordId).percentage = isNaN(percentage) ? 0 : percentage;
		this.onAmountChange.emit(this.allocatedAmounts);
	}
}
