import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { cloneDeep, Dictionary, flatMap, isEmpty, some, uniq } from "lodash";
import { BulkCreateStrategy, BulkCreateType, BulkNestedStrategy } from "rl-common/components/bulk-grid/bulk-config.strategy";
import { BulkEditsPackage } from "rl-common/components/bulk-grid/bulk-edit.models";
import { CharDataModifyAction } from "rl-common/models/char-data-modify-action";
import { FacetType } from "rl-common/models/facet-type";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IRelSearchDoc } from "rl-common/models/i-rel-search-doc";
import { BitmapAllocatorService } from "rl-common/services/bitmap-allocator/bitmap-allocator.service";
import { BulkGridService } from "rl-common/services/bulk-grid/bulk-grid.service";
import { IEntityRecTemplateCharData } from "rl-common/services/entity/models/i-entity-rec-template-char-data";
import { ITemplateCharData } from "rl-common/services/entity/models/i-template-char-data";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { GridColumnTypes } from "rl-common/services/grid-view/models/grid-column-types";
import { IExtraGridColumnResults } from "rl-common/services/grid-view/models/i-extra-grid-column-results";
import { IGridView } from "rl-common/services/grid-view/models/i-grid-view";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { QueryUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { combineLatest, merge, Observable, of, Subscription } from "rxjs";
import { defaultIfEmpty, filter, finalize, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { ISelectEntityRelationshipStateEvent } from "../associations/entity-relationships/entity-relationship/models/i-select-entity-relationship-state-event";
import { SearchFieldNames } from "../entities/entity-search/query.models";
import { ISelectStateChangeEvent } from "../entities/entity-search/select-state-net-changes";
import { GridDataSourceBuilder } from "../grid/datasource/builders/grid-datasource-builder";
import { BulkDataSource } from "../grid/datasource/bulk/bulk-datasource";
import { BulkEditNestedDataSource } from "../grid/datasource/bulk/bulk-edit/bulk-edit-nested-datasource";
import { CharDataEditViewColumnStrategy } from "../grid/datasource/columns/char-data-edit-view-column-strategy";
import { GridViewColumnStrategy } from "../grid/datasource/columns/grid-view-column-strategy";
import { CommonGridDataSource } from "../grid/datasource/common-grid.datasource";
import { EntityCharDataDataChangeStrategy } from "../grid/datasource/data-change/entity-chardata-data-change.strategy";
import { EntityCharDataGridDataSource } from "../grid/datasource/entity-chardata-grid.datasource";
import { IGridCoreDataSource } from "../grid/datasource/grid-core-datasource";
import { GridNestedTemplateDirective } from "../grid/directives/grid-nested-template.directive";
import { GridTableComponent } from "../grid/grid-table/grid-table.component";
import { GridOptions } from "../grid/models/grid-options";
import { JobProgressComponent } from "../modals/job-progress-modal/job-progress/job-progress.component";
import { PanelContentDirective } from "../panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "../panel-switcher/panel-switcher.component";
import { PanelComponent } from "../panel-switcher/panel/panel.component";
import { CharTypeId } from "./../../rl-common.consts";
import { SessionService } from "./../../services/session.service";
import { IDUtil } from "./../../utils/id.util";
import { IGridDataSource } from "./../grid/datasource/grid-datasource";
import { BulkAmountsComponent } from "./bulk-amounts/bulk-amounts.component";
import { BulkConfig, BulkGridStep, BulkType } from "./bulk-config.strategy";
import { BulkGridSelectAssociationsComponent } from "./bulk-grid-select-associations/bulk-grid-select-associations.component";
import { IBulkMetaData, IBulkMetaDataRequest, INewEntityTemplateCharData, IPHBulkCreateRequest, IPHBulkUpdateRequest } from "./bulk-grid.models";
import { BulkService } from "./bulk.service";


export interface IBulkEvent {
	createdEntityIds: string[];
	modifiedEntityIds: string[];
}

@Component({
	selector: "rl-bulk-grid",
	templateUrl: "./bulk-grid.component.html",
	styleUrls: ["./bulk-grid.component.scss"],
	providers: [ParentEntityService, BulkGridService, BitmapAllocatorService],
	imports: [PanelSwitcherComponent, PanelComponent, PanelContentDirective, NgIf, GridTableComponent, GridNestedTemplateDirective, BulkAmountsComponent, JobProgressComponent, BulkGridSelectAssociationsComponent, AsyncPipe]
})
export class BulkGridComponent implements OnInit, OnDestroy {
	@Input()
	config: BulkConfig;

	@Input()
	template: number;

	@Input()
	gridView: IGridView;

	@Optional()
	@Input()
	initialData: ITemplateCharData[];

	// TODO: Do we really want to pass in the grid options here? could they change?
	_gridOptions: GridOptions<IEntityRecTemplateCharData>;

	@Output()
	onComplete = new EventEmitter<boolean>();

	@Output()
	onDone = new EventEmitter<IPHBulkCreateRequest>();

	@ViewChild("grid")
	grid: GridTableComponent;

	dataSource: BulkDataSource<ITemplateCharData | IEntitySearchDoc, unknown>;
	private subs: Subscription[] = [];
	jobId: string;
	step = BulkGridStep.BulkEdit;
	parentTemplateId: number;
	parentCharTypeId: number;
	childCharTypeId: number;
	selectedAssocs: IRecordTitle[] = [];
	selectedAssocState: ISelectEntityRelationshipStateEvent<number, IEntitySearchDoc>;
	editedRowData: ITemplateCharData;

	initialTaggedEntities: Dictionary<Dictionary<IRecordTitle[]>> = {};
	initialCharData: ITemplateCharData[];
	initialNestedCharData: { [rowPath: string]: ITemplateCharData[] } = {};

	assocRowPath: string;
	assocColumnKey: string | number;
	assocRowEdits: Dictionary<Dictionary<IRecordTitle[]>> = {};

	bulkEditsPackage: BulkEditsPackage<ICharacteristicData[]>;
	// TODO: Swap out for OneConfig
	bulkMetaData: IBulkMetaData;
	headerText: string;
	selectAssociationCharTypeId: number;
	selectedStateDictionary: Dictionary<Dictionary<ISelectStateChangeEvent<number, IEntitySearchDoc>>> = {};

	invalid$: Observable<boolean> = of(false);

	isBusy = false;
	isCurrencyValidationEnabled: boolean;

	@Input()
	set gridOptions(value: GridOptions<IEntityRecTemplateCharData>) {
		const clone = cloneDeep(value);
		this._gridOptions = clone;
	}

	get bulkCreateStrategy() {
		if (!this.config || this.config.bulkStrategy.bulkType !== BulkType.Create) {
			return null;
		}
		return this.config.bulkStrategy as BulkCreateStrategy;
	}

	get isBulkCreateSelectAssociated() {
		return !!(this.bulkCreateStrategy && this.bulkCreateStrategy.bulkCreateType === BulkCreateType.SelectedAssociated);
	}

	get isListPage() {
		return !this._modDetailService;
	}

	get hasEdits() {
		if (!this.dataSource) {
			return false;
		}

		const hasChanges = !!this.dataSource.isModified;
		if (!this.dataSource.nestedStrategy?.nestedDataSourceMap$?.value) {
			return hasChanges;
		}

		const nestedDsMap = this.dataSource.nestedStrategy?.nestedDataSourceMap$?.value ?? new Map<string, IGridDataSource<unknown>>();
		const nestedDataSources = Array.from(nestedDsMap.values())
			.map(ds => {
				const dataSource = ds as CommonGridDataSource<unknown, unknown>;
				if (dataSource.modifiedRowDatas$ && dataSource.modifiedColumnDatas$) {
					return dataSource;
				}
				return null;
			})
			.filter(x => !!x);

		const hasNestedChanges = some(nestedDataSources, ds => ds.isModified);

		return hasChanges || hasNestedChanges;
	}

	constructor(
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _sessionService: SessionService,
		private readonly _bulkService: BulkService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _cdRef: ChangeDetectorRef,
		@Optional() private readonly _modDetailService: ModDetailService,
		readonly _parentEntityService: ParentEntityService,
		readonly _bulkGridService: BulkGridService,
		private readonly _bitmapAllocator: BitmapAllocatorService,
		private readonly _featureService: FeatureService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit() {
		this._bulkGridService.setConfig(this.config);
		this.parentCharTypeId = this.config.parentCharTypeId;
		this.parentTemplateId = this.config.parentTemplateId;
		const parentRecordId = this.config.parentRecordId;
		this.headerText = this.getHeaderText();
		if (this.isBulkCreateSelectAssociated) {
			this.selectAssociationCharTypeId = this.bulkCreateStrategy.associatedCharTypeId;
			if (this.parentCharTypeId && parentRecordId) {
				this._parentEntityService.initializeParent({
					charTypeId: this.config.charTypeId,
					templateId: this.config.templateId,
					recordId: -1,
					parent: {
						charTypeId: this.parentCharTypeId,
						recordId: parentRecordId,
						templateId: this.parentTemplateId
					}
				});
			}
			this.step = BulkGridStep.SelectAssocEntityRows;
		} else {
			if (this.parentCharTypeId && parentRecordId) {
				this._parentEntityService.initializeParent({ charTypeId: this.parentCharTypeId, recordId: parentRecordId, templateId: this.parentTemplateId });
			}
			this.setupDataSource();
		}
		const sub = this._featureService.isEnabled$(FeatureKeys.CurrencyValidation).subscribe(isEnabled => {
			this.isCurrencyValidationEnabled = isEnabled;
		});
		this.subs.push(sub);
	}

	private pipeBulkEditPackage(dataSource): Observable<BulkEditsPackage<ICharacteristicData[]>> {
		return merge(dataSource.modifiedRowDatas$, dataSource.modifiedColumnDatas$)
			.pipe(
				map(() => {
					let cmds = null;
					if (this.config.templateId) {
						cmds = this._oneConfigService.getTemplateCmds(this.config.charTypeId, this.config.templateId);
					} else {
						cmds = this._oneConfigService.getCharTypeCmds(this.config.charTypeId);
					}
					return dataSource.dataChangeStrategy.makeCharDataBulkEditPackage(cmds, this._bulkGridService.columnEditDateMathTempId);
				}));
	}

	private openNestedRows(rowData) {
		if (!rowData || !this.dataSource.nestedStrategy) {
			return of([]);
		}
		return rowData.map(rd => {
			const gridNestedStrategy = this.dataSource.nestedStrategy;
			return gridNestedStrategy.toggleOpen(rd.rowPath, rd.data, true).pipe(
				filter(r => !!r),
				take(1),
				switchMap(() => gridNestedStrategy.nestedDataSourceMap$.value.get(rd.rowPath).rowData$
					.pipe(
						tap((nestedRowData: INewEntityTemplateCharData[]) => {
							this.initialNestedCharData[rd.rowPath] = cloneDeep(nestedRowData);
						}),
					))
			);
		});
	}

	private setupDataSource() {
		const requests: IBulkMetaDataRequest[] = [{ charTypeId: this.config.charTypeId, templateId: this.template }];

		const bulkMetaData$ = this._bulkService.getBulkMetaData(requests)
			.pipe(
				map(bmd => {
					const res = this.buildCharDataDs(this.config, bmd);
					return res;
				}),
				shareReplay(1),
				take(1),
				tap((res) => {
					this.dataSource = res.dataSource;
					this.bulkMetaData = res.bulkMetaData;
				}),
			);

		const sub = bulkMetaData$
			.pipe(
				filter(res => res.dataSource.dataChangeStrategy),
				switchMap(res => this.pipeBulkEditPackage(res.dataSource)))
			.subscribe(p => this.bulkEditsPackage = p);

		const sub2 = bulkMetaData$
			.pipe(
				switchMap(res => res.dataSource.rowData$
					.pipe(
						filter(rowData => !!rowData),
						take(1),
						tap(rowData => {
							this.initialCharData = cloneDeep((rowData as ITemplateCharData[]));
							if (this.initialData) {

								this.initialCharData.forEach((rec) => {
									this.dataSource.dataChangeStrategy.setRowDataChange(rec.id, `${GridColumnTypes.AssocEntity}_${CharTypeId.Property}_name`, rec);
									const charIds = uniq(rec.charDatas.map(x => x.charactersticID));
									const tagLabels = rec.template.characteristicMetaDatas.filter(x => charIds.indexOf(x.characteristicID) > -1).map(s => s.tagLabel);
									tagLabels.forEach((tl) => {
										this.dataSource.dataChangeStrategy.setRowDataChange(rec.id, tl, rec)
									});
								});
								this.pipeBulkEditPackage(this.dataSource).subscribe((p) => {
									this.bulkEditsPackage = p;
								});
							}
						}),
					)),
			).subscribe();

		const sub3 = bulkMetaData$
			.pipe(switchMap(res => res.dataSource.fetchRows()))
			.subscribe();

		const combinedOpenedRows$ = bulkMetaData$
			.pipe(switchMap(res => res.dataSource.indexedRowData$))
			.pipe(filter(rowData => !!rowData))
			.pipe(switchMap(rowData => {
				const openedRows$ = this.openNestedRows(rowData);
				if (openedRows$.length === 0) {
					return of([]);
				}
				return combineLatest(openedRows$);
			}));

		const sub4 = combinedOpenedRows$.subscribe();
		this.invalid$ = combinedOpenedRows$
			.pipe(
				switchMap(() => this.isInvalid$()),
				defaultIfEmpty(true)
			);

		const sub5 = this._bulkGridService.applyColumnEdit$.subscribe(event => {
			if (this.config.bulkStrategy.bulkType == BulkType.Create) {
				// if a datemath rel created a characteristic locally we must add it via a column edit
				this.dataSource.dataChangeStrategy.setColumnEditChange(event.cmd.tagLabel, event.charData, CharDataModifyAction.Replace);
			}
		});

		this.subs.push(sub, sub2, sub3, sub4, sub5);
	}

	private getHeaderText() {
		switch (this.config.bulkStrategy.bulkType) {
			case BulkType.Create:
				return this.getBulkCreateText();
			case BulkType.Edit:
			case BulkType.Nested:
				return "Bulk Edit";
			default:
				return "Bulk";
		}
	}

	showFieldsWarningText() {
		return this.config.bulkStrategy.bulkType !== BulkType.Create;
	}

	private getBulkCreateText() {
		switch (this.bulkCreateStrategy.bulkCreateType) {
			case BulkCreateType.Amount:
				return `Bulk Create ${this.bulkCreateStrategy.amount} Rows`;
			case BulkCreateType.DirectlyAssociated:
				return `Bulk Create Directly Associated ${CharTypeIdUtil.toDisplayNamePlural(this.bulkCreateStrategy.associatedCharTypeId)}`;
			case BulkCreateType.SelectedAssociated:
				return `Bulk Create Selected Associated ${CharTypeIdUtil.toDisplayNamePlural(this.bulkCreateStrategy.associatedCharTypeId)}`;
			case BulkCreateType.Avails:
				return `Bulk Create ${this.bulkCreateStrategy.amount} Rights`;
			default:
				throw new Error(`Bulk Create Type ${BulkCreateType[this.bulkCreateStrategy.bulkCreateType]} not supported`);
		}
	}

	private buildCharDataDs(config: BulkConfig, bulkMetaDataDict: { [charTypeId: number]: { [templateId: number]: IBulkMetaData } }) {
		let ds = null;
		const bulkMetaData: IBulkMetaData = bulkMetaDataDict[config.charTypeId][this.template];
		switch (config.bulkStrategy.bulkType) {
			case BulkType.Edit:
				const template = this._oneConfigService.getTemplate(config.charTypeId, config.templateId);
				const ids = this._gridDataSourceBuilder.bulkEditDataSource(config, template?.templateGroupID, this.gridView);
				ds = ids;
				const sub = ids.extraGridColumnResults$
					.pipe(filter(extraColResults => !!extraColResults), take(1)).subscribe(extraColResults => {
						const initial = this.convert(config, extraColResults);
						this.initialTaggedEntities = initial;
						this.assocRowEdits = cloneDeep(initial);
					});
				this.subs.push(sub);
				break;
			case BulkType.Create:
				this._parentEntityService.contextTemplateId = config.templateId;
				ds = this._gridDataSourceBuilder.bulkCreateDataSource(bulkMetaData, config, this.selectedAssocState, this._bitmapAllocator, this.initialData);
				if (this.initialData) {
					this.pipeBulkEditPackage(ds).subscribe((p) => {
						this.bulkEditsPackage = p;
					});
				}
				break;
			case BulkType.Nested:
				const nestedStrategy = config.bulkStrategy as BulkNestedStrategy;
				const parentCharTypeId = config.charTypeId;
				const parentTemplateId = this.template;
				const charTypeId = nestedStrategy.charTypeId;
				const templateId = config.templateId; // irrelevant.
				ds = this._gridDataSourceBuilder.bulkEditNestedDataSource(parentCharTypeId, parentTemplateId, config.parentRecordId, config);
				break;
		}
		return {
			dataSource: ds,
			bulkMetaData
		};
	}

	private convert(config: BulkConfig, extraColResults: IExtraGridColumnResults): _.Dictionary<_.Dictionary<IRecordTitle[]>> {
		const result = cloneDeep(extraColResults.assoc_entity) || {};
		const records = Object.keys(result);
		records.forEach(record => {
			const assocCharTypeIds =
				this._oneConfigService.getChildAssocCharTypeIds(config.charTypeId);
			assocCharTypeIds.filter(taggedCharTypeId => !result[record][taggedCharTypeId])
				.forEach(taggedCharTypeId => {
					result[record][taggedCharTypeId] = [];
				});
		});
		return result;
	}

	bulk() {
		switch (this.config.bulkStrategy.bulkType) {
			case BulkType.Create:
				this.bulkCreate();
				break;
			case BulkType.Nested:
			case BulkType.Edit:
				this.bulkEdit();
				break;
		}
	}

	bulkEdit() {
		this.isBusy = true;
		const sub = this.confirm$()
			.pipe(
				filter(confirm => confirm),
				switchMap(() => this.bulkEdit$()),
				finalize(() => {
					this.isBusy = false;
				})
			).subscribe();
		this.subs.push(sub);
	}

	bulkEdit$() {
		if (this.config.bulkStrategy.bulkType === BulkType.Nested) {
			return this.bulkEditNested$();
		} else {
			return this.bulkEditGrid$();
		}
	}

	confirm$() {
		if (this.config.bulkStrategy.bulkType === BulkType.Nested || (this.bulkEditsPackage.columnEdits.length === 0 && this.bulkEditsPackage.extraColumnEdits.length === 0)) {
			return of(true);
		}
		const ds = (this.dataSource as unknown) as EntityCharDataGridDataSource;
		const facetResults = ds.facetResults$.value;
		if (!facetResults[FacetType.Template]) {
			return of(true);
		}
		const hasMultipleTemplates = facetResults[FacetType.Template].length > 1;
		if (!hasMultipleTemplates) {
			return of(true);
		}
		return this._modalService.confirmBulkMultipleTemplates(this.bulkEditsPackage, this.config.charTypeId, this.config.templateId, ((this.dataSource as unknown) as EntityCharDataGridDataSource));
	}

	isInvalid$() {
		if (this.config.bulkStrategy.bulkType === BulkType.Nested) {
			if (this.config.bulkStrategy.bulkType === BulkType.Nested && this.dataSource?.nestedStrategy) {
				const nestedDses = Array.from(this.dataSource.nestedStrategy.nestedDataSourceMap$.value.values());
				const obss = nestedDses.map(ds => this.dataSourceInvalid$(ds));
				return combineLatest(obss)
					.pipe(
						map(isInvalidArr => isInvalidArr.some(isInvalid => isInvalid))
					);
			}
		}
		return this.dataSourceInvalid$(this.dataSource);
	}

	dataSourceInvalid$(ds: IGridCoreDataSource<unknown>) {
		return ds.formGroup.statusChanges
			.pipe(
				map(() => ds.formGroup.invalid),
				tap(() => this._cdRef.detectChanges())
			);
	}

	private getEmailView() {
		if (!this.isListPage) {
			return null;
		}
		const columnStrategy = this.dataSource.columnStrategy as CharDataEditViewColumnStrategy<ITemplateCharData>;
		return this.gridView ?? columnStrategy.gridView$.value;
	}

	bulkCreate() {
		this.isBusy = true;
		const bulkCreateStrategy = this.config.bulkStrategy as BulkCreateStrategy;
		const view = this.getEmailView();
		const request: IPHBulkCreateRequest = {
			bulkCreateType: bulkCreateStrategy.bulkCreateType,
			numRows: this.dataSource.rowCount$.value,
			charTypeId: this.config.charTypeId,
			parentEntityId: this.config.getParentEntityId(this._sessionService.divId),
			package: this.bulkEditsPackage,
			templateId: this.template,
			exportEmailView: view
		};
		if (bulkCreateStrategy.bulkCreateType !== BulkCreateType.Amount) {
			request.model = this.dataSource.searchModel;
		}

		if (this.config.create) {
			const sub = this._bulkService.bulkCreate(request).pipe(
				finalize(() => {
					this.isBusy = false;
				})
			).subscribe(jobId => {
				this.jobId = jobId;
				this.step = BulkGridStep.InProgress;
			});
			this.subs.push(sub);
		} else {
			this.onDone.emit(request);
		}
	}

	bulkEditGrid$() {
		const newSearchModel = {
			...this.config.searchModel
		};

		if (!this.isListPage) {
			newSearchModel.start = 0;
			newSearchModel.rows = this.dataSource.rowCount$.value;
			(newSearchModel as any).gridViewColumns = this.gridView.columns;
			if (isEmpty(this.bulkEditsPackage.columnEdits) && isEmpty(this.bulkEditsPackage.extraColumnEdits)) {
				const recordIds = uniq([...Object.keys(this.bulkEditsPackage.rowEdits).map(rId => +rId), ...Object.keys(this.bulkEditsPackage.extraRowEdits).map(rId => +rId)]);
				newSearchModel.filterQueries = [QueryUtil.$join_parent_rel(
					QueryUtil.$and(
						QueryUtil.$eq_any(SearchFieldNames.Relationship.childRecordID, recordIds)
					)
				)];
			} else {
				newSearchModel.start = 0;
				newSearchModel.rows = this.dataSource.rowCount$.value;
			}
		}
		const request: IPHBulkUpdateRequest = {
			package: this.bulkEditsPackage,
			charTypeId: this.config.charTypeId,
			parentEntityId: this.config.getParentEntityId(this._sessionService.divId),
			model: newSearchModel,
			numRows: this.dataSource.rowCount$.value,
			exportEmailView: this.getEmailView(),
			isListPage: this.isListPage
		};
		return this.canUpdate$([request]).pipe(
			filter(x => x),
			switchMap(() => this._bulkService.bulkUpdate(request)),
			tap(jobId => {
				this.jobId = jobId;
				this.step = BulkGridStep.InProgress;
			})
		);
	}

	bulkEditNested$() {
		const req = this.getBulkUpdateNestedRequest();

		return this.canUpdate$(req).pipe(
			filter(x => x),
			switchMap(() => this._bulkService.bulkUpdateNested(req)),
			tap(jobId => {
				this.jobId = jobId;
				this.step = BulkGridStep.InProgress;
			})
		)
	}

	canUpdate$(req: IPHBulkUpdateRequest[]) {
		if (!this.isCurrencyValidationEnabled || req.find(x => x.charTypeId !== CharTypeId.Amount)) {
			return of(true);
		}

		return this._bulkService.canBulkUpdate(req).pipe(tap(canCreate => {
			if (!canCreate) {
				this._growlerService.error().growl("Error: Amounts of mixed currencies not allowed under single table row")
			}
		}));
	}

	getBulkUpdateNestedRequest() {
		const nestedStrategy = this.config.bulkStrategy as BulkNestedStrategy;
		return flatMap(Array.from(this.dataSource.nestedStrategy.nestedDataSourceMap$.value.entries()), (kvp) => {
			const rowPath = kvp[0];
			const nestedDataSource = kvp[1] as BulkEditNestedDataSource<ITemplateCharData>;
			const cmds = flatMap(nestedStrategy.templateIds, templateId => this._oneConfigService.getTemplateCmds(nestedStrategy.charTypeId, templateId));
			const dcs = nestedDataSource.dataChangeStrategy as EntityCharDataDataChangeStrategy<any>;
			const bulkPackage = dcs.makeCharDataBulkEditPackage(cmds, this._bulkGridService.columnEditDateMathTempId);
			const parent = this.dataSource.indexedRowData$.value.find(ird => ird.rowPath === rowPath).data as IRelSearchDoc;
			const parentEntity = IDUtil.toID(this._sessionService.divId, CharTypeId.Relationship, parent.relRecId);
			const colStrategy = nestedDataSource.columnStrategy as GridViewColumnStrategy<IRelSearchDoc>;
			const request: IPHBulkUpdateRequest = {
				charTypeId: nestedStrategy.charTypeId,
				package: bulkPackage,
				model: {
					gridViewColumns: colStrategy.gridView$.value.columns,
					query: nestedDataSource.query,
					rows: nestedDataSource.rowCount$.value,
					start: 0
				},
				numRows: nestedDataSource.rowCount$.value,
				parentEntityId: parentEntity,
			};
			return request;
		});
	}

	getCharTypeId(columnKey: string) {
		const split = columnKey.split("_");
		if (split.length < 4 || split[0] !== "assoc" || split[1] !== "entity" || isNaN(+split[2])) {
			throw new Error(`Error parsing columnKey: ${columnKey}`);
		}
		return +split[2];
	}

	cancelEditTaggedEntity() {
		this.step = BulkGridStep.BulkEdit;
	}

	cancelSelectAssociations() {
		if (this.isBulkCreateSelectAssociated) {
			this.cancel();
		} else {
			this.step = BulkGridStep.BulkEdit;
		}
	}

	applySelectedAssociations(state: ISelectEntityRelationshipStateEvent<number, IEntitySearchDoc>) {
		this.step = BulkGridStep.BulkEdit;
		this.selectedAssocState = state;
		this.setupDataSource();
	}

	cancel() {
		this.onComplete.next(false);
	}

	complete() {
		if (!this.isListPage) {
			const sub = this._modDetailService.refreshCharData$().pipe(
				finalize(() => {
					this.onComplete.next(true);
				})
			).subscribe();

			this.subs.push(sub);
		} else {
			this.onComplete.next(true);
		}
	}

	getExtraRowEdits(selectedStateDictionary: Dictionary<Dictionary<ISelectStateChangeEvent<number, IEntitySearchDoc>>>) {
		return Object.entries(selectedStateDictionary).reduce((acc, curr) => {
			const rowKey = curr[0];
			const dict = curr[1];
			const newDict = Object.entries(dict).reduce((acc2, curr2) => {
				const charTypeId = curr2[0];
				const state = curr2[1];
				acc2[charTypeId] = { addedIds: Array.from(state.netChanges.addedIds), deletedIds: Array.from(state.netChanges.deletedIds) };
				return acc2;
			}, {});
			acc[rowKey] = newDict;
			return acc;
		}, {});
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
