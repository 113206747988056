import { NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { find } from "lodash";
import { EditContactsComponent } from "rl-common/components/contacts/edit-contacts/edit-contacts.component";
import { ISearchEvent } from "rl-common/components/contacts/edit-contacts/edit-party/edit-party.component";
import { IContactPartyGroup } from "rl-common/components/contacts/models/i-contact-party-group";
import { IContactsDictionaries } from "rl-common/components/contacts/models/i-contacts-dictionaries";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { ModuleAssociationComponent } from "rl-common/components/new-association-modal/new-association-wizard/module-association/module-association.component";
import { PanelContentDirective } from "rl-common/components/panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "rl-common/components/panel-switcher/panel-switcher.component";
import { EditMode } from "rl-common/components/panel-switcher/panel-switcher.models";
import { PanelComponent } from "rl-common/components/panel-switcher/panel/panel.component";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntity } from "rl-common/models/i-entity";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { ContactService } from "rl-common/services/contact/contact.service";
import { EditContactsService } from "rl-common/services/edit-contacts/edit-contacts.service";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { IParentEntity, ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { Subscription } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

@Component({
	selector: "rl-associate-catalogs",
	templateUrl: "./associate-catalogs.component.html",
	styleUrl: "./associate-catalogs.component.scss",
	providers: [ParentEntityService, EditContactsService],
	imports: [PanelSwitcherComponent, PanelComponent, PanelContentDirective, NgIf, ModuleAssociationComponent]
})
export class AssociateCatalogsComponent implements OnInit, OnChanges {

	contactPartyGroups: IContactPartyGroup[];
	dictionaries: IContactsDictionaries = {} as IContactsDictionaries;
	editMode: EditMode = EditMode.Search;
	selectedPartyId: number;
	selectedContactId: number;
	selectedGroupId: number;
	selectedSequenceNumber: number;
	searchOptions: SearchOptions;
	isLoading = true;
	dataSource: AssocModuleSelectDataSource<IEntitySearchDoc, IEntitySearchDoc>;
	state: IEntityRelationshipState<number, IEntitySearchDoc>;
	private readonly _subscriptions: Subscription[] = [];

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	parentRecId: number;

	@Input()
	parentCharTypeId: number;

	@Input()
	autoCreate: boolean;

	@Input()
	canAutoCreateChildItems: boolean;

	@Input()
	canAssociateCatalogItems: boolean;

	@Input()
	extractedCatalogItems: Map<number, IEntity[]> = new Map();// Template, Title


	@ViewChild(EditContactsComponent, { static: true })
	editContactsComponent: EditContactsComponent;

	@ViewChild(PanelSwitcherComponent, { static: true })
	panelSwitcher: PanelSwitcherComponent;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onCreate = new EventEmitter<boolean>();

	@Output()
	onBack = new EventEmitter<void>();

	@Output()
	onGoToNextStep = new EventEmitter<void>();

	catalogCharTypeId = CharTypeId.Property;

	constructor(
		private router: Router,
		private readonly _contactService: ContactService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _editContactsService: EditContactsService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly cd: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this._parentEntityService.initializeParent({
			charTypeId: this.charTypeId,
			recordId: -1,
			templateId: this.templateId
		});
		this.editMode = EditMode.Search;
		// this.refreshCatalog();
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.extractedCatalogItems && this.extractedCatalogItems) {
			this.refreshCatalog();
		}
	}

	updateState(state: IEntityRelationshipState<number, IEntitySearchDoc>) {
		this.state = state;
	}

	refreshCatalog() {
		this.isLoading = false;
		const obs = this.buildDataSource$();

		this._subscriptions.push(obs.subscribe());
		this.cd.detectChanges();
	}

	buildDataSource$(templateIds: number[] = null) {

		const parent: IParentEntity = {
			charTypeId: this.charTypeId,
			recordId: -1,
			templateId: this.templateId
		};

		const catalogIds: number[] = [];

		if (this.extractedCatalogItems) {
			const keys = Object.keys(this.extractedCatalogItems);
			for (const key of keys) {
				this.extractedCatalogItems[key].map(v => {
					if (v.recordID > 0) {
						catalogIds.push(v.recordID)
					}
				});
			}
		}

		this.dataSource = this._gridDataSourceBuilder.assocModuleSearchDataSource(CharTypeId.Property, parent, RelationshipTypes.Child, true, catalogIds,
			false, true, null, this._parentEntityService.partyId);
		return this.dataSource.indexedRowData$
			.pipe(
				filter((ird) => !!ird),
				take(1),
				tap((initialRowData) => {
					const dataSelectStrategy = this.dataSource.dataSelectStrategy;
					if (!catalogIds) {
						return;
					}
					if (dataSelectStrategy.selectAllEnabled && this.dataSource.rowCount$.value !== 0 && catalogIds.length === this.dataSource.rowCount$.value) {
						dataSelectStrategy.selectAll();
					} else {
						const selectedRowData = initialRowData.filter(rd => catalogIds.includes(rd.data.recordID));
						if (selectedRowData.length > 0) {
							this.dataSource.dataSelectStrategy.selectRows(selectedRowData);
						}
					}
				})
			);
	}

	saveChanges(shouldAutoCreateHierarchy: boolean) {
		this.isLoading = true;
		this.onCreate.emit(shouldAutoCreateHierarchy);
	}

	continueToAutoCreateHierarchy() {
		this.onGoToNextStep.emit();
	}

	saveContacts(entityId: string) {
		return this._editContactsService.save(entityId);
	}

	getContactsForNewEntityRequest() {
		return this._editContactsService.getContactsOnEntity();
	}

	cancel() {
		this._editContactsService.reset();
		this.onCancel.emit();
	}

	public startSearch(event: ISearchEvent) {
		this.editMode = EditMode.Search;
		this.selectedContactId = event.contactRecordId;
		this.selectedPartyId = event.partyId;
		this.selectedGroupId = event.groupId;
		this.selectedSequenceNumber = event.sequenceNumber;
		this.searchOptions = SearchOptionsFactory.buildPanelSearchOptions(this.charTypeId, CharTypeId.Property, this.templateId);
		this.searchOptions.filteredTemplateIds = event.filteredTemplateIds;
	}

	public select(event: GridSelectState<number, IEntitySearchDoc>) {
		if (event.selectedValues.size === 0) {
			return;
		}
		this.switchPanel(EditMode.Search);
		const party = this._editContactsService.findParty(this.selectedGroupId, this.selectedPartyId);
		const selectedValue = Array.from(event.selectedValues)[0];
		if (this.selectedContactId) {
			const contact = find(party.contacts, c => c.recordId === this.selectedContactId);
			contact.recordId = selectedValue.recordID;
		} else {
			party.contacts.push({
				recordId: selectedValue.recordID,
				sequenceNumber: this.selectedSequenceNumber
			});
		}

		if (!this.dictionaries.editContacts[selectedValue.recordID]) {
			this.dictionaries.editContacts[selectedValue.recordID] = selectedValue.title;
		}
	}

	goToNextWizardStep() {
		this.onGoToNextStep.emit();
	}

	goToPreviousWizardStep() {
		this.onBack.emit();
	}

	private switchPanel(editMode: EditMode) {
		this.editMode = editMode;
		if (this.panelSwitcher) {
			this.panelSwitcher.select(this.editMode);
		}
	}
}
