<div class="modal-body" data-cy="ModalBody">
	<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl" [destroyOnHide]="false">
		<li ngbNavItem>
			<a ngbNavLink>
				<div [attr.data-cy]="'SelectExistingTab_Deal'">
					Select Existing Deal
				</div>
			</a>
			<ng-template ngbNavContent>
				<div class="mt-3">
					<rl-module-association *ngIf="dataSource" [dataSource]="dataSource" [charTypeId]="charTypeId"
						(onEntityRelationshipChange)="updateState($event)">

					</rl-module-association>
				</div>
				<div class="modal-footer" data-cy="ModalFooter">
					<button class="btn btn-light me-3" (click)="cancel()" data-cy="CancelNewAssociationModalButton">
						Cancel
					</button>
					<button class="btn btn-success" type="button" data-cy="SaveNewAssociationModalButton"
						(click)="apply()" [disabled]="!isValid">
						Next <i class="far fa-chevron-right ms-2"></i>
					</button>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink>
				<div [attr.data-cy]="'CreateNewTab_Deal'">Create New Deal</div>
			</a>
			<ng-template ngbNavContent>
				<rl-create-entity [charTypeId]="charTypeId" [templateId]="templateId" (onEntityCreated)="nextPageLogic($event)"
					(onCancelEvent)="cancel()">
				</rl-create-entity>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>