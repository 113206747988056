import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import _ from "lodash";
import { EditEntityUtils } from "rl-common/components/edit-entity/edit-entity/edit-entity.utils";
import { SystemIndicators } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { Subscription } from "rxjs";
import { AddressEditComponent } from "../address-edit/address-edit.component";

@Component({
	selector: "rl-address-edit-modal",
	imports: [AddressEditComponent],
	templateUrl: "./address-edit-modal.component.html",
	styleUrl: "./address-edit-modal.component.scss"
})
export class AddressEditModalComponent implements OnInit, OnDestroy {
	@Input()
	recordId: number;

	@Input()
	template: ICharacteristicMetaDataCollection

	@Input()
	charData: ICharacteristicData[];

	@Input()
	addressQuery: ISearchRequestModel;

	@Input()
	parentEntityId: string;

	@Output()
	onComplete = new EventEmitter<boolean>();

	subs: Subscription[] = [];
	originalCharData = [];
	isSaving = false;

	constructor(private readonly _entityService: EntityService
	) {

	}
	ngOnInit(): void {
		this.originalCharData = _.cloneDeep(this.charData);
		if (this.recordId) {
			const sub = this._entityService.getCharData(`d${this.template.divID}c${this.template.charTypeID}r${this.recordId}`)
				.subscribe((cd) => {
					this.charData = cd;
				});
			this.subs.push(sub);
		}
	}

	cancel() {
		this.onComplete.emit(false);
	}

	updateWorkingCharData(event: ICharacteristicData[]) {
		this.charData = event;
	}

	save() {
		this.isSaving = true;
		const pack = EditEntityUtils.getChanges(this.recordId ?? 0, this.charData, this.template.characteristicMetaDatas);
		const cmd = this.template.characteristicMetaDatas.find(cmd => cmd.systemIndicator === SystemIndicators.AddressIsPrimary);
		const wasPrimaryEdited = !!this.charData.find(cd => cd.charactersticID === cmd.characteristicID)?.value;
		const sub = this._entityService.upsertAddress(this.recordId, this.template.charTypeID, this.template.templateID, this.parentEntityId, pack, this.addressQuery, wasPrimaryEdited)
			.subscribe(() => {
				this.isSaving = false;
				this.onComplete.emit()
			});
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
