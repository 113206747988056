import { WizardComponent } from "@achimha/angular-archwizard";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { ICharDataChangedEvent } from "rl-common/components/char-data/char-data.models";
import { AssociateContactsComponent } from "rl-common/components/create-workflow/associate-contacts.component";
import { ITemplateHierarchy, OnEntityCreatedEvent } from "rl-common/components/create-workflow/create-entity.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { IDUtil, QueryUtil } from "rl-common/utils";
import { Subscription } from "rxjs";
import { CreateEntityComponent } from "../../../../../common/components/create-workflow/create-entity.component";
import { GridDataSourceBuilder } from "../../../../../common/components/grid/datasource/builders/grid-datasource-builder";
import { EntitySearchDataSource } from "../../../../../common/components/grid/datasource/search/entity-search.datasource";
import { ModuleAssociationComponent } from "../../../../../common/components/new-association-modal/new-association-wizard/module-association/module-association.component";
import { ICreateAssociationEvent, IDealAssociation, ITemporaryRecordTitle } from "./existing-or-new-deal.models";
import { OneConfigService } from "../../../../../common/services/one-config/one-config.service";

@Component({
	selector: "rl-existing-or-new-deal",
	templateUrl: "./existing-or-new-deal.component.html",
	styleUrls: ["./existing-or-new-deal.component.scss"],
	providers: [ParentEntityService],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgIf, ModuleAssociationComponent, CreateEntityComponent, NgbNavOutlet]
})
export class ExistingOrNewDealComponent implements OnDestroy {

	newRecordTitle: ITemporaryRecordTitle = null;
	charTypeId = CharTypeId.Transaction;

	@Input()
	hasValidCreateTemplates: boolean;

	@Output()
	onCreate = new EventEmitter<ICreateAssociationEvent>();

	@Output()
	onApply = new EventEmitter<IDealAssociation>();

	@Output()
	onCancel = new EventEmitter<void>();

	@ViewChild(WizardComponent)
	wizard: WizardComponent;

	@ViewChild(AssociateContactsComponent)
	associateContacts: AssociateContactsComponent;

	viewNewRecordAfterCreation = true;

	isSaving = false;
	isCreating = false;
	createdEntityId: string;
	jobId: string;
	title: string;
	canAutoCreateHierarchy: boolean;
	childTemplates: ITemplateHierarchy[] = [];
	dataSource: EntitySearchDataSource<IEntitySearchDoc, unknown>;
	documentCharType = CharTypeId.Document;

	public charTypeDisplayName: string;
	public charTypeDisplayNamePlural: string;
	state: IEntityRelationshipState<number, IEntitySearchDoc>;

	public form: UntypedFormGroup;

	private readonly _subscriptions: Subscription[] = [];

	get isValid() {
		if (!this.dataSource) {
			return false;
		}
		const state = this.dataSource.dataSelectStrategy.selectedState;
		let gridCount = 0;
		if (!state.isAllSelected) {
			gridCount = state.selectedIds.size;
		}

		if ((gridCount <= 0 && !this.newAssociationTemplate) && (this.state && this.state.inputListSelected?.length <= 0)) {
			return false;
		}

		if (this.newAssociationTemplate && (!this.form.valid || !this.form.dirty)) {
			return false;
		}

		return true;
	}

	get newAssociationTemplate() {
		return this.form.get("newAssociationTemplate").value as ICharacteristicTemplate;
	}

	get newAssociationCharData() {
		return this.form.get("newAssociationCharData").value as ICharDataChangedEvent;
	}

	private validCharData = (c: UntypedFormControl) => {
		if (!c.value) {
			return { charDataNull: true };
		}
	};

	templateId: number;

	constructor(
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _oneConfigService: OneConfigService
	) {
		// Default Create Entity to the first Template
		this.templateId = this._oneConfigService.getTemplates(this.charTypeId)[0].templateID;

		this.buildForm();
		this.buildDataSource();
	}

	buildDataSource() {
		const columnStrategy = this._gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(this.charTypeId);
		const dataSelectStrategy = this._gridDataSourceBuilder.dataSelectStrategies
			.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc)
			.withSelectType(GridSelectType.Radio);

		this.dataSource = this._gridDataSourceBuilder.entitySearchDataSource(this.charTypeId)
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnStrategy);
	}

	buildForm() {
		const newAssociationTemplateFormControl = new UntypedFormControl(null);
		const newCharDataControl = new UntypedFormControl(null, [this.validCharData]);
		const onlyShowRequiredControl = new UntypedFormControl(false);
		this.form = this._formBuilder.group({
			newAssociationTemplate: newAssociationTemplateFormControl,
			newAssociationCharData: newCharDataControl,
			onlyShowRequired: onlyShowRequiredControl
		});
	}

	cancel() {
		this.onCancel.emit();
	}

	emitCreate() {
		this.isSaving = false;
		this.isCreating = false;
		this.onCreate.emit({ jobId: this.jobId, title: this.title });
	}

	continueToNextStep() {
		this.wizard.goToNextStep();
	}

	buildModelFromSelection(relationshipState: IEntityRelationshipState<number, IEntitySearchDoc>, filterQueries: IQueryNode[], query: IQueryNode): ISearchRequestModel {
		if (!relationshipState) {
			return null;
		}
		const gridSelectState = relationshipState.gridSelected;
		query = cloneDeep(query);
		filterQueries = cloneDeep(filterQueries);
		if (!gridSelectState.isAllSelected && gridSelectState.selectedIds.size === 0 && this.state?.inputListSelected.length === 0) {
			return null; // nothing selected, return null
		}

		const inputListIds = relationshipState?.inputListSelected ? relationshipState?.inputListSelected.map(r => r.recordID) : [];
		let selectedRowCount = 0;
		if (gridSelectState.isAllSelected) {
			const deselected = Array.from(gridSelectState.deselectedIds) as number[];
			if (deselected.length > 0) {
				const notDeselected = QueryUtil.$not(QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, deselected));
				filterQueries.push(notDeselected);
			}
			if (this.dataSource.selectableInnerQuery) {
				filterQueries.push(this.dataSource.selectableInnerQuery);
			}
			selectedRowCount = this.dataSource.availableRowCount$.value - deselected.length;
		} else {
			filterQueries = [];
			const gridSelectedIds = Array.from(gridSelectState.selectedIds) as number[];
			const ids = [...gridSelectedIds, ...inputListIds];
			const selected = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, ids);
			filterQueries.push(selected);
			selectedRowCount = ids.length;
		}
		return {
			query,
			keywords: gridSelectState.isAllSelected ? this.dataSource.keywords : null,
			filterQueries,
			rows: selectedRowCount,
			start: 0,
		};
	}

	updateState(state: IEntityRelationshipState<number, IEntitySearchDoc>) {
		this.state = state;
	}

	apply() {
		if (!this.isValid) {
			return;
		}

		const newAssociationTemplate = this.newAssociationTemplate;
		const newAssociationCharData = this.newAssociationCharData;
		const state = this.dataSource.dataSelectStrategy.selectedState as GridSelectState<number, IEntitySearchDoc>;
		const selectedCount = state.isAllSelected ? this.dataSource.rowCount$.value - state.deselectedIds.size : state.selectedIds.size;
		const newEntityEntered = newAssociationTemplate && newAssociationCharData;

		if ((this.dataSource.dataSelectStrategy.selectType === GridSelectType.Radio && (selectedCount > 0 && newEntityEntered))) {
			return this._modalService.alert("Alert", "Only one contact is allowed on this party.").subscribe(() => {
				this.isSaving = false;
			});
		} else {
			if (newEntityEntered) {
				if (this.dataSource.dataSelectStrategy.selectType === GridSelectType.Radio && selectedCount > 0) {
					this.isSaving = false;
				}
			}

			const val = Array.from(state.selectedValues)[0];
			this.onApply.emit({
				recordId: val.recordID,
				templateId: val.templateID
			});
		}
	}

	goToPreviousWizardStep() {
		this.wizard.goToPreviousStep();
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	onHierarchyCreated() {
		this.emitCreate();
	}

	nextPageLogic(event: OnEntityCreatedEvent) {
		const entityId = event.newEntityId;
		const split = IDUtil.splitEntityID(entityId);

		this.onApply.emit({
			templateId: event.templateId,
			recordId: split.recID
		});
	}
}
