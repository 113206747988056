<div class="modal-header" data-cy="EntitySearchModalHeader">
	<h2 class="modal-title">Select {{ searchOptions.charTypeId | pluralCharTypeName }}</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()"
		data-cy="EntitySearchModalButtonXCancel">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" data-cy="EntitySearchModalBody">
	<ul ngbNav #nav="ngbNav" class="nav nav-tabs" [destroyOnHide]="false" [(activeId)]="activeTabId">
		<li [ngbNavItem]="1">
			<a ngbNavLink>Search</a>
			<ng-template ngbNavContent>
				<rl-entity-search #entitySearch [searchOptions]="searchOptions"
								  (selectedStateChange)="selectedStateChange($event)">
				</rl-entity-search>
			</ng-template>
		</li>
		<li [ngbNavItem]="2" *ngIf="showInputList">
			<a ngbNavLink>Input IDs</a>
			<ng-template ngbNavContent>
				<rl-input-list [childCharTypeId]="searchOptions.charTypeId" (onValidate)="updateValidated($event)"
							   [includeParentRecordId]="false"></rl-input-list>
			</ng-template>
		</li>
		<li [ngbNavItem]="3" *ngIf="searchOptions.charTypeId === 1">
			<a ngbNavLink data-cy="WorksheetTab">Worksheets</a>

			<ng-template ngbNavContent>
				<rl-clipboard-selection-grid (onWorksheetSelected)="worksheetSelected($event)"></rl-clipboard-selection-grid>
			</ng-template>
		</li>
		<li [ngbNavItem]="4" *ngIf="allowCreate">
			<a ngbNavLink>Create New {{ searchOptions.charTypeId | charTypeName:false }}</a>
			<ng-template ngbNavContent>
				<rl-create-entity-basic #entityCreate [charTypeId]="this.searchOptions.charTypeId"
										[availableTemplateIds]="this.searchOptions.createTemplateIds">
				</rl-create-entity-basic>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>
<div class="modal-footer" data-cy="EntitySearchModalFooter">
	<button class="btn btn-light" (click)="cancel()" data-cy="EntitySearchModalButtonCancel">
		Cancel
	</button>
	<button class="btn btn-success" (click)="apply()" [disabled]="!canComplete"
		data-cy="EntitySearchModalButtonSuccess">
		{{saveButtonText}}
	</button>
</div>